import { makeAutoObservable, runInAction } from "mobx"
import to from "await-to-js"
import { DateTime } from "luxon"
import { Loader, resHandler } from "kui-utils"
import { CurrencyVariants } from "../../../../../types/store/apartmentChart"
import { GetChartPricesResponse } from "../../../../../types/api/apartmentChartAPI"
import ApartmentChartTickStore from "./ApartmentChartTick"
import ApartmentChartAgent from "../../../../../../../agent/ApartmentChart"
import PromotionPageStore from "../../PromotionPageStore"
import {
  ChartTickParams,
  RentalContractTickParams,
  VolumeOfApartmentsParams,
} from "../../../types/store/apartmentChart"
import {
  GetVolumeOfApartmentsResponse,
  RentalContractTickModel,
  RentalContractTicksResponse,
  VolumeOfApartmentsModel,
} from "../../../types/api/promotionChannelsAPI"
import ApartmentPromotionAgent from "../../../../../../../agent/ApartmentPromotion"

const TICK_COUNT = 5
const TICK_SIZE = 5000

class ApartmentChartStore {
  predictorTicks: ApartmentChartTickStore[]

  currency: CurrencyVariants

  rentalContractTicks: RentalContractTickParams[]

  volumeOfApartments: VolumeOfApartmentsParams[]

  loader: Loader

  promotionStore: PromotionPageStore

  constructor(promotionStore: PromotionPageStore) {
    this.predictorTicks = []
    this.volumeOfApartments = []
    this.rentalContractTicks = []
    this.currency = "RUB"
    this.loader = new Loader()
    this.promotionStore = promotionStore
    makeAutoObservable(this)
  }

  getChartInfo = async () => {
    const apartmentId = this.promotionStore.apartmentStore.overviewStore.id
    if (apartmentId) {
      this.loader.startLoading()

      await Promise.allSettled([
        this.getHistoryById(apartmentId),
        this.getRentalContractTicks(),
        this.getVolumeOfApartments(),
      ])

      this.loader.endLoading()
    }
  }

  getHistoryById = async (apartmentId: number) => {
    const currentDate = DateTime.now()
    const startDate = currentDate.minus({ month: 3 }).toISODate()!
    const endDate = currentDate.toISODate()!

    const [err, res] = await to<GetChartPricesResponse>(
      ApartmentChartAgent.getHistoryCosts(apartmentId, startDate, endDate)
    )

    runInAction(() => {
      if (res && !err) {
        const lastPrice = res[res.length - 1]?.price
        this.predictorTicks = res.map(
          (item) => new ApartmentChartTickStore(item)
        )
        if (lastPrice) {
          this.promotionStore.channelsStore.contentStore.updatePredictorPrice(
            Number(lastPrice)
          )
        }
      } else {
        this.loader.setError("fetch prices", err)
      }
    })
  }

  getVolumeOfApartments = async () => {
    const currentDate = DateTime.now()
    const startDate = currentDate.minus({ month: 3 }).toISODate()!
    const endDate = currentDate.toISODate()!

    const response = await to<GetVolumeOfApartmentsResponse>(
      ApartmentPromotionAgent.getVolumeOfApartments(startDate, endDate)
    )

    resHandler(response, this.loader, (res) => {
      this.volumeOfApartments = ApartmentChartStore.getApartmentVolumeTicks(
        res.report
      )
    })
  }

  getRentalContractTicks = async () => {
    const contractId =
      this.promotionStore.apartmentStore.overviewStore.actualRentalContractId

    if (contractId) {
      const currentDate = DateTime.now()
      const startDate = currentDate.minus({ month: 3 }).toISODate()!
      const endDate = currentDate.toISODate()!

      const response = await to<RentalContractTicksResponse>(
        ApartmentPromotionAgent.getRentalContractPrices(
          contractId,
          startDate,
          endDate
        )
      )

      resHandler(response, this.loader, this.updateRentalContractTicks)
    }
  }

  updateRentalContractTicks = (res: RentalContractTicksResponse) => {
    this.rentalContractTicks = res.prices.map((tick) =>
      ApartmentChartStore.getRentalContractTick(tick)
    )
  }

  get predictorCosts() {
    return this.predictorTicks.map((item) => item.price || 0)
  }

  get rcMaxCosts() {
    return this.rentalContractTicks.map((item) => item.maxPrice || 0)
  }

  get rcMinCosts() {
    return this.rentalContractTicks.map((item) => item.minPrice || 0)
  }

  get minCost() {
    if (this.chartTicks.length > 0) {
      const min = Math.min(...this.predictorCosts, ...this.rcMinCosts)
      return Math.floor(min / TICK_SIZE) * TICK_SIZE
    }
    return 0
  }

  get maxCost() {
    if (this.chartTicks.length > 0) {
      const max = Math.max(...this.predictorCosts, ...this.rcMaxCosts)
      return Math.ceil(max / TICK_SIZE) * TICK_SIZE
    }
    return 0
  }

  get costsTicks() {
    const allTicksCount = (this.maxCost - this.minCost) / TICK_SIZE
    const tickCount =
      allTicksCount > TICK_COUNT ? TICK_COUNT : Math.floor(allTicksCount)
    const divisionSize =
      Math.floor((this.maxCost - this.minCost) / tickCount / TICK_SIZE) *
      TICK_SIZE
    const arr = new Array(tickCount).fill(0)
    return [
      ...arr.map((_, index) => this.minCost + divisionSize * index),
      this.maxCost,
    ]
  }

  get monthTicks() {
    let prevItem = 0
    const params: any = {}
    this.predictorTicks.forEach((item: any, index: number) => {
      const prev = this.predictorTicks[index - 1]?.formattedMonth
      const current = this.predictorTicks[index]?.formattedMonth

      if (current !== prev) {
        params[index] = {
          label: current,
        }
        if (params[prevItem]) {
          params[prevItem].ticksCount = index - prevItem
        }
        prevItem = index
      }
    })

    return params
  }

  get managerEstimatedPrices() {
    const { minPrice, maxPrice, startAdvertisingDate, endAdvertisingDate } =
      this.promotionStore.channelsStore.contentStore

    if (!startAdvertisingDate || !endAdvertisingDate || !minPrice || !maxPrice)
      return []

    const { startTickIndex, endTickIndex, ticks } =
      ApartmentChartStore.setTicksLimits(
        this.chartTicks || [],
        minPrice,
        maxPrice,
        startAdvertisingDate,
        endAdvertisingDate
      )

    let index = 0
    return ticks.map((tick) => {
      if (tick?.managerPrice === 0) {
        /*
         * с price === 0 специально определен тик,
         * который находится в промежутке между максимальной и минимальной стоимостью
         * */
        index += 1
        /*
         * Создаем тик с промежуточной стоимостью, чтобы получился график-прямая
         * (плавно снижаем значение от верней границы до нижней)
         * */
        return {
          ...tick,
          managerPrice:
            maxPrice -
            ((maxPrice - minPrice) / (endTickIndex - startTickIndex)) * index,
        }
      }
      return tick
    })
  }

  get chartTicks() {
    return new Array(
      Math.max(this.rentalContractTicks.length, this.predictorTicks.length)
    )
      .fill(1)
      .map((_, index) => ({
        ...(this.predictorTicks[index] || {}),
        ...(this.rentalContractTicks[index] || {}),
      })) as ChartTickParams[]
  }

  static setTicksLimits = (
    ticks: ChartTickParams[],
    minPrice: number,
    maxPrice: number,
    startAdvertisingDate: DateTime,
    endAdvertisingDate: DateTime
  ) => {
    let startTickIndex = 0
    let endTickIndex = 0

    const formattedTicks = ticks
      .map((tick, i) => {
        if (tick.date) {
          const prevTick = ticks[i - 1]
          const nextTick = ticks[i + 1]
          const isStartTick =
            prevTick?.date &&
            prevTick.date < startAdvertisingDate &&
            tick.date >= startAdvertisingDate
          const isEndTick =
            !nextTick ||
            (nextTick && nextTick?.date && nextTick?.date > endAdvertisingDate)

          if (
            tick.date < startAdvertisingDate ||
            tick.date > endAdvertisingDate
          ) {
            /*
             * Создаем тик с price null, чтобы текущий тик не отобразился на графике,
             * но при этом сам график отобразился корректно (иначе график сместится влево)
             */
            return new ApartmentChartTickStore({
              price: tick.price,
              managerPrice: null,
              time_model: tick.date.toISODate()!,
            })
          }
          if (isStartTick) {
            startTickIndex = i
            return new ApartmentChartTickStore({
              price: tick.price,
              managerPrice: maxPrice,
              time_model: startAdvertisingDate.toISODate()!,
            })
          }
          if (isEndTick) {
            endTickIndex = i
            return new ApartmentChartTickStore({
              price: tick.price,
              managerPrice: minPrice,
              time_model:
                endAdvertisingDate > tick.date
                  ? tick.date.toISODate()!
                  : endAdvertisingDate.toISODate()!,
            })
          }
          /*
           * определяем тик, который находится между минимумом и максимумом,
           * чтобы потом задать ему стоимость (потом - потому что еще не известна длина диапазона между мин и максом)
           * */
          return new ApartmentChartTickStore({
            price: tick.price,
            managerPrice: 0,
            time_model: tick.date.toISODate()!,
          })
        }
        return null
      })
      .filter((tick) => tick)

    return {
      startTickIndex,
      endTickIndex,
      ticks: formattedTicks,
    }
  }

  static getApartmentVolumeTicks = (ticks: VolumeOfApartmentsModel[]) =>
    ticks.map((tick) => {
      const date = DateTime.fromISO(tick.date)
      return {
        date,
        count: tick.count,
        formattedDate: date?.toFormat("dd.MM.yy"),
      }
    })

  static getRentalContractTick = (
    tick: RentalContractTickModel
  ): RentalContractTickParams => {
    const date = tick.date ? DateTime.fromISO(tick.date) : null
    const minPrice = Number(tick.min_price)
    const maxPrice = Number(tick.max_price)

    return {
      rentalContractPrice: Number(tick.price),
      minPrice,
      maxPrice,
      date,
      formattedDate: date?.toFormat("dd.MM.yy") || "",
      formattedMonth: date?.toFormat("MMMM").toLowerCase() || "",
      range: [minPrice, maxPrice],
    }
  }
}

export default ApartmentChartStore
