import _ from "lodash"
import { PartialPayer } from "kui-crm"
import {
  BooleanValues,
  PeriodValues,
  RentalTypesValues,
} from "../../../../utils/content/listsOptions"
import { RentalContractInfoValues } from "../../types/store/rcInfoStore"
import CheckboxWithController from "../../../../components/ui/CheckboxWithController"
import { InputsGroupWithTitleParams } from "../../../../components/common/InputsGroupWithTitle/types"
import EstateAgentField from "../../../../components/entityInputs/EstateAgentField"

const hintMessage = "The condition inherited from the service contract"

const getRCInfoFieldsGroups = (
  rcStore: Partial<RentalContractInfoValues>
): InputsGroupWithTitleParams<Partial<RentalContractInfoValues>>[] => [
  {
    title: "Internal info",
    fields: [
      {
        name: "estateAgent",
        size: 6,
        variant: "custom",
        CustomInput: EstateAgentField,
      },
    ],
  },
  {
    title: "Contract info",
    fields: [
      {
        label: "Number of contract",
        name: "number",
        size: 3,
        disabled: true,
      },
      {
        label: "Rental type",
        name: "type",
        size: 3,
        variant: "select",
        options: RentalTypesValues,
      },
      {
        label: "Contract type",
        name: "contractPeriodType",
        size: 3,
        variant: "select",
        options: PeriodValues,
      },
      {
        name: "autoProlongate",
        size: 3,
        variant: "custom",
        CustomInput: CheckboxWithController,
        label: "Auto prolongation",
        options: BooleanValues,
      },
      {
        label: "Sign date",
        name: "signDate",
        size: 3,
        variant: "date",
      },
      {
        label: "Start date",
        name: "startDate",
        size: 3,
        variant: "date",
      },
      {
        label: "End date",
        name: "endDate",
        size: 3,
        variant: "date",
      },
      ...(rcStore.status === "Closed"
        ? [
            {
              label: "Close date",
              name: "closeDate",
              size: 3,
              variant: "date",
            },
          ]
        : []),
      {
        label: "Next inspection date",
        name: "nextInspectionDate",
        size: 3,
        disabled: true,
        hint: hintMessage,
      },
    ],
  },

  {
    title: "Price terms",
    fields: [
      {
        label: "Rent price",
        name: "rentPrice",
        size: 3,
        cornerLabel: "₽",
        variant: "mask",
        mask: Number,
        thousandsSeparator: " ",
      },
      {
        label: "Initial commission",
        name: "initialCommission",
        size: 3,
        type: "number",
        cornerLabel: "%",
      },
      {
        label: "Security payment",
        name: "securityPayment",
        size: 3,
        type: "number",
        cornerLabel: "%",
      },
      {
        label: "With delay",
        name: "installmentPeriod",
        size: 3,
        type: "number",
        cornerLabel: "month",
      },
      {
        label: "Payment date",
        name: "paymentDay",
        size: 3,
        type: "number",
      },
    ],
  },

  {
    title: "Maintenance terms",
    fields: [
      {
        label: "Allowable price deviation",
        name: "allowablePriceDeviation",
        size: 3,
        type: "number",
        cornerLabel: "%",
        disabled: true,
        hint: hintMessage,
      },
      {
        label: "First appraisal period",
        name: "firstAppraisalPeriod",
        size: 3,
        type: "number",
        cornerLabel: "month",
        disabled: true,
        hint: hintMessage,
      },
      {
        label: "Inspection",
        name: "inspectionIntervalMonth",
        size: 3,
        type: "number",
        cornerLabel: "month",
        disabled: true,
        hint: hintMessage,
      },
    ],
  },

  {
    title: "Terminations terms",
    fields: [
      {
        label: "Showing duration",
        name: "showingDays",
        size: 3,
        type: "number",
        cornerLabel: "days",
      },
      {
        label: "Penalty",
        name: "penalty",
        size: 3,
        type: "number",
        cornerLabel: "%",
      },
      {
        label: "Notification period",
        name: "notifyPeriodMonth",
        size: 3,
        type: "number",
        cornerLabel: "month",
        disabled: true,
        hint: hintMessage,
      },
    ],
  },

  {
    title: "Additional terms",
    fields: [
      {
        label: "Pets allowed",
        name: "petsAllowed",
        variant: "custom",
        CustomInput: CheckboxWithController,
        size: 3,
        disabled: true,
      },
      {
        label: "Children allowed",
        name: "childrenAllowed",
        variant: "custom",
        CustomInput: CheckboxWithController,
        size: 3,
        disabled: true,
      },
      {
        label: "Smoking allowed",
        name: "smokingAllowed",
        variant: "custom",
        CustomInput: CheckboxWithController,
        size: 3,
        disabled: true,
      },
    ],
  },

  {
    title: "Utilities payers",
    fields:
      rcStore?.utilityPayers?.map((utilityPayer, index) => ({
        title: _.capitalize(utilityPayer.utilityType),
        name: `utilityPayers.${index}`,
        utilityType: utilityPayer.utilityType,
        variant: "custom",
        CustomInput: PartialPayer,
        fieldsVariant: "standard",
        size: 12,
        disabled: true,
        hint: hintMessage,
      })) || [],
  },
]

export default getRCInfoFieldsGroups
