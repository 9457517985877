import { makeAutoObservable } from "mobx"
import { Loader, Paginator, SortingFilter } from "kui-utils"
import to from "await-to-js"
import { DateTime } from "luxon"
import RegistriesAgent from "../../../../agent/Registries"
import ApartmentsStore from "../../../../store/lites/ApartmentsStore"
import { ImpairmentsParams } from "../../types/store/impairmentsStore"
import {
  ImpairmentRegistryModel,
  ImpairmentsRegistryResponse,
} from "../../types/api/impairments"

class ImpairmentsRegistryStore {
  impairments: ImpairmentsParams[]

  paginator: Paginator

  filter: SortingFilter

  loader: Loader

  creationLoader: Loader

  constructor() {
    this.impairments = []
    this.paginator = new Paginator()
    this.filter = new SortingFilter("-id")
    this.loader = new Loader()
    this.creationLoader = new Loader()
    makeAutoObservable(this)
  }

  fetchAll = async () => {
    this.loader.startLoading()

    const [err, res] = await to<ImpairmentsRegistryResponse>(
      RegistriesAgent.getImpairmentsRegistry(
        this.paginator.offset,
        this.paginator.limit,
        this.filter.filterParams
      )
    )

    if (res && !err) {
      const mapper = (inspection: ImpairmentRegistryModel) =>
        ImpairmentsRegistryStore.getImpairmentsRegistryParams(inspection)

      this.impairments = this.paginator.getPageResponse<
        ImpairmentRegistryModel,
        ImpairmentsParams
      >(res, this.impairments, mapper)
    } else {
      this.loader.setError("fetch services", err)
    }
    this.loader.endLoading()
  }

  static getImpairmentsRegistryParams = (
    inspection: ImpairmentRegistryModel
  ): ImpairmentsParams => ({
    id: inspection.id,
    apartment: ApartmentsStore.getApartmentLinkParams(inspection.apartment),
    date: inspection.date ? DateTime.fromISO(inspection.date) : null,
    price: inspection.price ? Number(inspection.price) : 0,
    status: inspection.status,
  })
}

export default ImpairmentsRegistryStore
