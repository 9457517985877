import _ from "lodash"
import { InputMultiSelect } from "kui-complex"
import { PartialPayer } from "kui-crm"
import {
  TaxTypeValues,
  PeriodValues,
  RentalTypesValues,
  TaxResidenceValues,
} from "../../../../utils/content/listsOptions"
import { FieldGroupsParams } from "../../../../components/common/UpdatedFieldsGroup/types"
import { ServiceContractInfoValues } from "../../types/store/scInfoStore"
import CheckboxWithController from "../../../../components/ui/CheckboxWithController"
import { inheritedContractFieldMessage } from "../../../../utils/content/constants"

const getSCInfoFieldsGroups = (
  scFormFields: Partial<ServiceContractInfoValues>
): FieldGroupsParams<Partial<ServiceContractInfoValues>>[] => [
  {
    title: "Contract info",
    fields: [
      {
        label: "Number of contract",
        name: "number",
        size: 3,
        disabled: true,
      },
      {
        label: "Contract type",
        name: "contractPeriodType",
        size: 3,
        variant: "select",
        options: PeriodValues,
      },
      {
        label: "Sign date",
        name: "signDate",
        size: 3,
        variant: "date",
      },
      {
        label: "Start date",
        name: "startDate",
        size: 3,
        variant: "date",
      },
      {
        label: "End date",
        name: "endDate",
        size: 3,
        variant: "date",
      },
      ...(scFormFields.status === "Closed"
        ? [
            {
              label: "Close date",
              name: "closeDate",
              size: 3,
              variant: "date",
            },
          ]
        : []),
      {
        label: "Long term type",
        name: "rentPeriodType",
        size: 3,
        variant: "custom",
        CustomInput: InputMultiSelect,
        options: RentalTypesValues,
      },
      {
        name: "autoProlongate",
        size: 3,
        variant: "custom",
        label: "Auto prolongation",
        CustomInput: CheckboxWithController,
      },
    ],
  },

  {
    title: "Price terms",
    fields: [
      {
        label: "Minimum rent price",
        name: "minRentPrice",
        size: 3,
        cornerLabel: "₽",
        variant: "mask",
        mask: Number,
        thousandsSeparator: " ",
      },
      {
        label: "Maximum rent price",
        name: "maxRentPrice",
        size: 3,
        cornerLabel: "₽",
        variant: "mask",
        mask: Number,
        thousandsSeparator: " ",
      },
      {
        label: "Initial commission",
        name: "initialCommission",
        size: 3,
        type: "number",
        cornerLabel: "%",
      },
      {
        label: "Maint. commission",
        name: "maintenanceCommission",
        size: 3,
        type: "number",
        cornerLabel: "%",
      },
    ],
  },

  {
    title: "Maintenance terms",
    fields: [
      {
        label: "Advertising period",
        name: "advertisingPeriod",
        size: 3,
        type: "number",
        cornerLabel: "month",
      },
      {
        label: "Allowable price deviation",
        name: "allowablePriceDeviation",
        size: 3,
        type: "number",
        cornerLabel: "%",
        hint: inheritedContractFieldMessage,
      },
      {
        label: "First appraisal period",
        name: "firstAppraisalPeriod",
        size: 3,
        type: "number",
        cornerLabel: "month",
        hint: inheritedContractFieldMessage,
      },
      {
        label: "Inspection",
        name: "inspectionIntervalMonth",
        size: 3,
        type: "number",
        cornerLabel: "month",
        hint: inheritedContractFieldMessage,
      },
    ],
  },

  {
    title: "Terminations terms",
    fields: [
      {
        label: "Penalty with rental",
        name: "penaltyWithRental",
        size: 3,
        type: "number",
        cornerLabel: "%",
      },
      {
        label: "Penalty with prepar.",
        name: "penaltyInPreparation",
        size: 3,
        cornerLabel: "₽",
        variant: "mask",
        mask: Number,
        thousandsSeparator: " ",
      },
      {
        label: "Notification period",
        name: "notifyPeriodMonth",
        size: 3,
        type: "number",
        cornerLabel: "month",
        hint: inheritedContractFieldMessage,
      },
    ],
  },

  {
    title: "Additional terms",
    fields: [
      {
        label: "Pets allowed",
        name: "petsAllowed",
        variant: "custom",
        CustomInput: CheckboxWithController,
        size: 3,
      },
      {
        label: "Children allowed",
        name: "childrenAllowed",
        variant: "custom",
        CustomInput: CheckboxWithController,
        size: 3,
      },
      {
        label: "Smoking allowed",
        name: "smokingAllowed",
        variant: "custom",
        CustomInput: CheckboxWithController,
        size: 3,
      },
      {
        label: "Term",
        name: "additionalTerms",
        isTextArea: true,
        size: 12,
      },
    ],
  },

  {
    title: "Utilities payers",
    fields: scFormFields?.utilityPayers?.map((utilityPayer, index) => ({
      title: _.capitalize(utilityPayer.utilityType),
      name: `utilityPayers.${index}`,
      utilityType: utilityPayer.utilityType,
      variant: "custom",
      CustomInput: PartialPayer,
      fieldsVariant: "standard",
      size: 12,
      hint: inheritedContractFieldMessage,
    })),
  },

  {
    title: "Taxes",
    fields: [
      {
        label: "Tax residence",
        name: "taxResidence",
        size: 3,
        variant: "select",
        options: TaxResidenceValues,
        disabled: true,
      },
      {
        label: "Type",
        name: "agentType",
        size: 3,
        variant: "select",
        options: TaxTypeValues,
      },
    ],
  },
]

export default getSCInfoFieldsGroups
