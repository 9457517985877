import React from "react"
import { Grid } from "kui-basic"
import { observer } from "mobx-react"
import { Loading } from "kui-complex"
import {
  getLinkCards,
  getListCards,
  getUpcomingInspectionsCards,
} from "./content"
import DashboardLinkCard from "../../../components/common/DashboardLinkCard"
import useDashboardStore from "../../../store"

const HousingManagerDashboardCards = () => {
  const { housingManagerDashboard } = useDashboardStore()
  const { actionLoader, loader } = housingManagerDashboard

  if (actionLoader.isLoading || loader.isLoading)
    return <Loading height="80vh" />

  const linkCards = getLinkCards(housingManagerDashboard)
  const listCards = getListCards(housingManagerDashboard)
  const upcomingInspectionsCards = getUpcomingInspectionsCards(
    housingManagerDashboard
  )

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} container spacing={4}>
        {linkCards.map((card) => (
          <Grid item key={card.label}>
            <DashboardLinkCard {...card} />
          </Grid>
        ))}
      </Grid>
      {listCards.map(({ size, card, key }) => (
        <Grid item xs={size || 4} key={key}>
          {card}
        </Grid>
      ))}
      <Grid item xs={12}>
        <Grid container spacing={4}>
          {upcomingInspectionsCards.map(({ size, card, key }) => (
            <Grid item xs={size || 4} key={key}>
              {card}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default observer(HousingManagerDashboardCards)
