import React from "react"
import { observer } from "mobx-react"
import { useNavigate } from "react-router-dom"
import { copyInfo, useToggle } from "kui-utils"
import PageHeader from "../../../../../components/common/PageHeader"
import { Routes } from "../../../../../types/route"
import useApartmentStore from "../../../store"
import ConfirmationModal from "../../../../../components/common/ConfirmationModal"
import { OptionDropdownType } from "../../../../../components/ui/DropdownMenu/types"

const APP_URL = process.env.REACT_APP_URL
const MAIN_URL = "https://maroom.ru"

function ApartmentHeader() {
  const { overviewStore, archiveApartment, isArchived } = useApartmentStore()
  const [isOpenModal, openModal, closeModal] = useToggle()
  const archivedReason = isArchived ? "Object is archived" : ""

  const navigate = useNavigate()

  const handleClick = () => {
    navigate(Routes.apartments)
  }

  const handleArchive = async () => {
    await archiveApartment(overviewStore.id!)
  }

  const copyLink = () => {
    if (overviewStore.id) {
      copyInfo(`${APP_URL}${Routes.apartments}/${overviewStore.id}`)
    }
  }

  const openOnMainSite = () => {
    if (overviewStore.id) {
      window.open(`${MAIN_URL}/apartments/${overviewStore.id}`)
    }
  }

  const routes = [
    { link: `${Routes.apartments}`, title: "Objects" },
    { link: null, title: overviewStore.address },
  ]

  const menuOptions: OptionDropdownType[] = [
    { label: "Copy object link", handleClick: copyLink },
    {
      label: "View object on the site",
      disabledReason: archivedReason,
      handleClick: openOnMainSite,
    },
    {
      label: "Archive object",
      handleClick: openModal,
      disabledReason:
        archivedReason ||
        (overviewStore.actualServiceContractId
          ? "To archive an object, you need to close the service contract"
          : ""),
      permission: "objectArchive",
    },
  ]

  return (
    <>
      <PageHeader
        handleClick={handleClick}
        options={menuOptions}
        breadcrumbs={routes}
      />

      <ConfirmationModal
        label="Are you sure you want to archive the apartment?"
        name="apartmentArchiving"
        open={isOpenModal}
        handleClose={closeModal}
        handleSubmit={handleArchive}
      />
    </>
  )
}

export default observer(ApartmentHeader)
