import * as React from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import styled from "@emotion/styled"
import { Button, Grid } from "kui-basic"
import { CheckIcon, TrashIcon } from "kui-icon"
import { observer } from "mobx-react"
import ApartmentPaymentFields from "../../../components/tabs/ExpensesSummaryTab/ExpensesPaymentFields"
import { PaymentFormFields } from "../../../components/tabs/ExpensesSummaryTab/ExpensesPaymentFields/types"
import { EditPaymentFormProps } from "./types"
import useExpensesStore from "../../../store"
import PaymentEditingFormSchema from "./schema"

function PaymentEditingForm({ payment, onClick }: EditPaymentFormProps) {
  const { overviewStore } = useExpensesStore()

  const form = useForm<PaymentFormFields>({
    resolver: yupResolver(PaymentEditingFormSchema),
    defaultValues: {
      payerId: payment.payer.id,
      date: payment.date,
      amount: payment.amount,
      invoiceId: payment.invoice?.id,
      transactionType: payment.transactionType,
    },
  })

  const handleSubmit = async (data: PaymentFormFields) => {
    if (overviewStore.id) {
      await payment.editPayment(overviewStore.id, data)
    }
    onClick()
  }

  const handleDelete = () => {
    if (overviewStore.id) payment.deletePayment(overviewStore.id)
  }

  return (
    <StyledForm
      data-testid="edit_payment_form"
      onSubmit={form.handleSubmit(handleSubmit)}
    >
      <Grid container wrap="nowrap">
        <ApartmentPaymentFields form={form} />
        <StyledButtonsWrapper
          wrap="nowrap"
          container
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <Button
              data-testid="cancel_edit"
              variant="whiteWithGray"
              onClick={onClick}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="orange"
              startIcon={<TrashIcon />}
              onClick={handleDelete}
              data-testid="delete_payment"
            >
              Delete
            </Button>
          </Grid>
          <Grid item>
            <Button
              data-testid="save_payment"
              startIcon={<CheckIcon />}
              type="submit"
            >
              Save
            </Button>
          </Grid>
        </StyledButtonsWrapper>
      </Grid>
    </StyledForm>
  )
}

export default observer(PaymentEditingForm)

const StyledButtonsWrapper = styled(Grid)`
  width: auto;
  margin-left: auto;
  padding-right: 3px;
  button {
    padding: 0 24px;
  }
`

const StyledForm = styled.form`
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.palette.grey.fifteenB};
  }
  &:last-child {
    border-bottom: 1px solid transparent;
  }
`
