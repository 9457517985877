import React from "react"
import { Caption } from "kui-basic"
import _ from "lodash"
import styled from "@emotion/styled"
import { FilledApartmentDocumentRowProps } from "./types"
import GridTableRow from "../../../../../../components/common/GridTableRow"
import GridCellWithLabel from "../../../../../../components/common/GridCellWithLabel"
import SquareFileBillet from "../../../../../../components/common/SquareFileBillet"
import getApartmentDocumentCellsWidth from "./content"
import ApartmentDocumentMenu from "../ApartmentDocumentMenu"

const FilledApartmentDocumentRow = (props: FilledApartmentDocumentRowProps) => {
  const { document, variant, disabled } = props
  const cellWidth = getApartmentDocumentCellsWidth(variant)

  return (
    <StyledGridTableRow cellsWidth={cellWidth}>
      <div>
        <Caption size="l" weight={600}>
          {_.capitalize(variant)}
        </Caption>
      </div>
      <GridCellWithLabel value={document.number} label="Number" />
      {variant === "insurance" && (
        <GridCellWithLabel
          value={`${document.price?.toLocaleString() || "-"} ₽`}
          label="Price"
        />
      )}
      <GridCellWithLabel
        value={document.startDate?.toFormat("dd.MM.yyyy")}
        label="Start date"
      />
      <GridCellWithLabel
        value={document.endDate?.toFormat("dd.MM.yyyy")}
        label="End date"
      />
      {document.file ? <SquareFileBillet link={document.file.url} /> : "-"}
      <ApartmentDocumentMenu disabled={disabled} variant={variant} />
    </StyledGridTableRow>
  )
}

export default FilledApartmentDocumentRow

const StyledGridTableRow = styled(GridTableRow)`
  height: 64px;
`
