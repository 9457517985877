import React, { useEffect, useRef, useState } from "react"
import { OptionParams } from "kui-complex/InputSelectBase"
import { inspectionsRequests } from "kui-crm_actions"
import { InspectionTypes, LoaderState } from "kui-crm"
import { observer } from "mobx-react"
import { InputSelectWithController, Loading } from "kui-complex"
import styled from "@emotion/styled"
import { Loader } from "kui-utils"
import useApartmentLiteStore from "../../../../../store/shared/apartment"
import { ApartmentInspectionValues } from "../../../../../utils/content/listsOptions"
import { InspectionTypeFiledProps } from "./types"

const InspectionTypeFiled = (props: InspectionTypeFiledProps) => {
  const { form } = props
  const { inspectionsStore } = useApartmentLiteStore()
  const [inspectionTypesOptions, setInspectionTypesOptions] = useState<
    OptionParams[]
  >([])
  const loaderRef = useRef(new Loader(true))
  const loader = loaderRef.current

  const getInspectionTypes = async () => {
    if (inspectionsStore.apartmentId) {
      loader.startLoading()
      const [err, inspectionTypes] =
        await inspectionsRequests.getInspectionTypes(
          inspectionsStore.apartmentId
        )

      if (err) loader.setError("getting inspection type", err)

      if (inspectionTypes)
        setInspectionTypesOptions(
          ApartmentInspectionValues.filter((option) =>
            inspectionTypes.includes(option.value as InspectionTypes)
          )
        )

      loader.endLoading()
    }
  }

  useEffect(() => {
    getInspectionTypes()
  }, [])

  return (
    <StyledWrapper>
      {loader.isLoading && (
        <StyledLoadingWrapper>
          <Loading height="100%" />
        </StyledLoadingWrapper>
      )}

      <InputSelectWithController
        isRequired
        disabled={!inspectionTypesOptions.length}
        label="Inspection type"
        name="type"
        form={form}
        options={inspectionTypesOptions}
        endIcon={!inspectionTypesOptions.length && <Loading height="24px" />}
      />

      <LoaderState loader={loader} onlyError />
    </StyledWrapper>
  )
}

export default observer(InspectionTypeFiled)

const StyledWrapper = styled.div`
  position: relative;
`

const StyledLoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
`
