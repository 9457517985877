import { makeAutoObservable, runInAction } from "mobx"
import { Loader, resHandler } from "kui-utils"
import to from "await-to-js"
import CompaniesStore from "../entities/CompaniesStore"
import ApartmentsStore from "../entities/ApartmentsStore"
import { AccountantDashboardSettings } from "../../types/store/accountantDashboard"
import { callPromisesBySettings } from "../../../../utils/service/mapper"
import DashboardAgent from "../../../../agent/Dashboard"
import { DashboardSettingsModel } from "../../../../types/api/dashboard"
import AutoMetersStore from "../entities/AutoMetersStore"
import ContractsStore from "../entities/ContractsStore"
import DashboardPageStore from "../DashboardPageStore"
import {
  DashboardStoreInterface,
  WorkIndicatorParams,
} from "../../types/store/common"
import { UserRole } from "../../../../types/api/cabinet"
import MetersWithDeviatingValuesStore from "../entities/MetersWithDeviatingValuesStore"
import MetersVerificationsStore from "../entities/MetersVerificationsStore"
import PendingContractsStore from "../entities/PendingContractsStore"

class AccountantDashboardStore implements DashboardStoreInterface {
  tenantOverduePayments: WorkIndicatorParams | null

  landlordOverduePayments: WorkIndicatorParams | null

  notClosedPeriods: WorkIndicatorParams | null

  overdueInsurances: WorkIndicatorParams | null

  autoMeters: AutoMetersStore

  metersVerifications: MetersVerificationsStore

  companiesRequisites: CompaniesStore

  apartmentsServices: ApartmentsStore

  closingServiceContracts: ContractsStore

  closingRentalContracts: ContractsStore

  metersWithDeviating: MetersWithDeviatingValuesStore

  pendingServiceContracts: PendingContractsStore

  settings: AccountantDashboardSettings | null

  loader: Loader

  actionLoader: Loader

  dashboardStore: DashboardPageStore

  role: UserRole

  constructor(dashboardStore: DashboardPageStore) {
    this.tenantOverduePayments = null
    this.landlordOverduePayments = null
    this.notClosedPeriods = null
    this.overdueInsurances = null
    this.autoMeters = new AutoMetersStore(dashboardStore)
    this.metersVerifications = new MetersVerificationsStore(dashboardStore)
    this.companiesRequisites = new CompaniesStore(dashboardStore)
    this.apartmentsServices = new ApartmentsStore(dashboardStore)
    this.closingServiceContracts = new ContractsStore("service", dashboardStore)
    this.closingRentalContracts = new ContractsStore("rental", dashboardStore)
    this.metersWithDeviating = new MetersWithDeviatingValuesStore(
      dashboardStore
    )
    this.pendingServiceContracts = new PendingContractsStore(
      "service",
      dashboardStore
    )
    this.settings = null
    this.loader = new Loader(true)
    this.actionLoader = new Loader()
    this.dashboardStore = dashboardStore
    this.role = "accountant"
    makeAutoObservable(this)
  }

  initDashboard = async () => {
    this.loader.startLoading()

    await this.fetchSettings()
    await this.updateCards()

    this.loader.endLoading()
  }

  loadCards = async () => {
    this.actionLoader.startLoading()

    this.resetCards()
    await this.updateCards()

    this.actionLoader.endLoading()
  }

  updateCards = async () => {
    if (this.settings) {
      await callPromisesBySettings(this.settings, {
        tenantOverduePayments: this.fetchTenantOverduePayments,
        landlordOverduePayments: this.fetchLandlordOverduePayments,
        notClosedPeriods: this.fetchNotClosedPeriods,
        overdueInsurances: this.fetchOverdueInsurances,
        autoMeters: this.autoMeters.fetchAutoMeters,
        metersVerifications: this.metersVerifications.fetchMetersVerifications,
        apartmentsServices: this.apartmentsServices.fetchApartments,
        companiesRequisites: this.companiesRequisites.fetchCompanies,
        closingRentalContracts: this.closingRentalContracts.fetchContracts,
        closingServiceContracts: this.closingServiceContracts.fetchContracts,
        metersWithDeviating: this.metersWithDeviating.fetchMeters,
        pendingServiceContracts: this.pendingServiceContracts.fetchContracts,
      })
    }
  }

  fetchSettings = async () => {
    this.loader.startLoading()

    const response = await to<DashboardSettingsModel>(
      DashboardAgent.getSettings()
    )

    resHandler(response, this.loader, this.updateSettings)
  }

  editSettings = async (settings: AccountantDashboardSettings) => {
    this.loader.startLoading()

    const body = AccountantDashboardStore.getDashboardSettingsBody(settings)
    const response = await to(DashboardAgent.editSettings(body))

    resHandler(response, this.loader, this.updateSettings, "update settings", {
      withEndLoading: false,
    })

    this.resetCards()
    await this.updateCards()

    this.loader.endLoading()
  }

  updateSettings = (settings: DashboardSettingsModel) => {
    const { visible_blocks } = settings
    this.settings = {
      tenantOverduePayments: !!visible_blocks.overdue_tenant_payments,
      landlordOverduePayments: !!visible_blocks.overdue_landlord_payments,
      notClosedPeriods: !!visible_blocks.not_closed_periods,
      overdueInsurances: !!visible_blocks.overdue_insurances,
      autoMeters: !!visible_blocks.auto_metering_devices,
      metersVerifications: !!visible_blocks.meters_require_verification,
      companiesRequisites: !!visible_blocks.companies_with_wrong_requisites,
      apartmentsServices: !!visible_blocks.objects_without_services,
      closingServiceContracts: !!visible_blocks.closing_service_contracts,
      closingRentalContracts: !!visible_blocks.closing_rental_contracts,
      metersWithDeviating: !!visible_blocks.meters_with_deviating_values,
      pendingServiceContracts: !!visible_blocks.pending_service_contracts,
    }
  }

  fetchTenantOverduePayments = async () => {
    const response = await to(
      DashboardAgent.getWorkIndicators(
        "overdue_tenant_payments",
        this.dashboardStore.periodFilter
      )
    )

    runInAction(() => {
      resHandler(response, this.loader, (res) => {
        this.tenantOverduePayments = DashboardPageStore.getIndicatorParams(res)
      })
    })
  }

  fetchLandlordOverduePayments = async () => {
    const response = await to(
      DashboardAgent.getWorkIndicators(
        "overdue_landlord_payments",
        this.dashboardStore.periodFilter
      )
    )

    runInAction(() => {
      resHandler(response, this.loader, (res) => {
        this.landlordOverduePayments =
          DashboardPageStore.getIndicatorParams(res)
      })
    })
  }

  fetchNotClosedPeriods = async () => {
    const response = await to(
      DashboardAgent.getWorkIndicators(
        "not_closed_periods",
        this.dashboardStore.periodFilter
      )
    )

    runInAction(() => {
      resHandler(response, this.loader, (res) => {
        this.notClosedPeriods = DashboardPageStore.getIndicatorParams(res)
      })
    })
  }

  fetchOverdueInsurances = async () => {
    const response = await to(
      DashboardAgent.getWorkIndicators(
        "overdue_insurances",
        this.dashboardStore.periodFilter
      )
    )

    runInAction(() => {
      resHandler(response, this.loader, (res) => {
        this.overdueInsurances = DashboardPageStore.getIndicatorParams(res)
      })
    })
  }

  resetSettings = () => {
    this.settings = null
  }

  resetCards = () => {
    this.tenantOverduePayments = null
    this.landlordOverduePayments = null
    this.notClosedPeriods = null
    this.overdueInsurances = null
    this.autoMeters = new AutoMetersStore(this.dashboardStore)
    this.metersVerifications = new MetersVerificationsStore(this.dashboardStore)
    this.companiesRequisites = new CompaniesStore(this.dashboardStore)
    this.apartmentsServices = new ApartmentsStore(this.dashboardStore)
    this.closingServiceContracts = new ContractsStore(
      "service",
      this.dashboardStore
    )
    this.closingRentalContracts = new ContractsStore(
      "rental",
      this.dashboardStore
    )
    this.metersWithDeviating = new MetersWithDeviatingValuesStore(
      this.dashboardStore
    )
    this.pendingServiceContracts = new PendingContractsStore(
      "service",
      this.dashboardStore
    )
  }

  resetDashboard = () => {
    this.resetSettings()
    this.resetCards()
  }

  static getDashboardSettingsBody = (
    settings: AccountantDashboardSettings
  ): DashboardSettingsModel => ({
    visible_blocks: {
      overdue_tenant_payments: settings.tenantOverduePayments,
      overdue_landlord_payments: settings.landlordOverduePayments,
      overdue_insurances: settings.overdueInsurances,
      not_closed_periods: settings.notClosedPeriods,
      auto_metering_devices: settings.autoMeters,
      meters_require_verification: settings.metersVerifications,
      objects_without_services: settings.apartmentsServices,
      companies_with_wrong_requisites: settings.companiesRequisites,
      closing_rental_contracts: settings.closingRentalContracts,
      closing_service_contracts: settings.closingServiceContracts,
      meters_with_deviating_values: settings.metersWithDeviating,
      pending_service_contracts: settings.pendingServiceContracts,
    },
  })
}

export default AccountantDashboardStore
