import { makeAutoObservable } from "mobx"
import { Loader, resHandler } from "kui-utils"
import { DateTime } from "luxon"
import to from "await-to-js"
import { ApartmentInspectionParams } from "../../types/cards/inspections"
import {
  UpcomingInspectionModel,
  UpcomingInspectionResponse,
} from "../../types/api/inspections"
import ApartmentsStore from "../../../../store/lites/ApartmentsStore"
import DashboardAgent from "../../../../agent/Dashboard"
import DashboardPageStore from "../DashboardPageStore"

class UpcomingInspectionsStore {
  value: number | null

  done: number | null

  upcomingInspections: ApartmentInspectionParams[]

  loader: Loader

  dashboardStore: DashboardPageStore

  constructor(dashboardStore: DashboardPageStore) {
    this.value = null
    this.done = null
    this.upcomingInspections = []
    this.loader = new Loader()
    this.dashboardStore = dashboardStore
    makeAutoObservable(this)
  }

  fetchApartmentsInspections = async () => {
    const response = await to(
      DashboardAgent.getUpcomingInspections(
        this.dashboardStore.periodFilter.slice(1)
      )
    )

    resHandler(response, this.loader, this.updateApartmentsInspections)
  }

  updateApartmentsInspections = (res: UpcomingInspectionResponse) => {
    this.done = res.completed_count
    this.value = res.count
    this.upcomingInspections = res.results.map((inspection) =>
      UpcomingInspectionsStore.getApartmentParams(inspection)
    )
  }

  static getApartmentParams = ({
    apartment,
    action_date,
  }: UpcomingInspectionModel): ApartmentInspectionParams => ({
    date: action_date ? DateTime.fromISO(action_date) : null,
    ...ApartmentsStore.getObjectForMapParams(apartment),
  })
}

export default UpcomingInspectionsStore
