import React from "react"
import { Caption, Grid, Heading } from "kui-basic"
import styled from "@emotion/styled"
import { Theme } from "@emotion/react"
import { Tooltip } from "kui-crm"
import { WarningIcon } from "kui-icon"
import { DashboardCardInfoProps, LinkCardIconStylesProps } from "./types"
import { getCardColor } from "./content"
import InfoTooltip from "../../../../../components/common/InfoTooltip"

const DashboardCardInfo = (props: DashboardCardInfoProps) => {
  const { value, variant, compareVariant, status } = props
  const { label, icon, customHeader, unit, hint } = props
  const color = getCardColor({ value, variant, compareVariant, status })

  return (
    <Grid container alignItems="center">
      <StyledIconWrapper cardColor={color}>{icon}</StyledIconWrapper>
      {customHeader || (
        <div>
          <Grid container alignItems="center">
            <StyledHeading size="h2" cardColor={color}>
              {value?.toLocaleString() || 0} {unit}
            </StyledHeading>
            {status === "alert" && (
              <Tooltip
                placement="bottom"
                content={<Caption size="xs">There are expired data</Caption>}
              >
                <StyledWarningIcon />
              </Tooltip>
            )}
          </Grid>
          <Grid container alignItems="center">
            {!!label && <Caption weight={500}>{label}</Caption>}
            {!!hint && <StyledTooltip content={hint} />}
          </Grid>
        </div>
      )}
    </Grid>
  )
}

DashboardCardInfo.defaultProps = {
  compareVariant: "greater",
}

export default DashboardCardInfo

export const iconColors = (theme: Theme) => ({
  red: {
    color: theme.palette.red.fiftyP,
    background: theme.palette.red.five,
  },
  green: {
    color: theme.palette.green.fiftyP,
    background: theme.palette.green.five,
  },
  orange: {
    color: theme.palette.brand.main,
    background: theme.palette.brand.background,
  },
})

const StyledIconWrapper = styled.div<LinkCardIconStylesProps>`
  width: 56px;
  height: 56px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ cardColor, theme }) =>
    iconColors(theme)[cardColor].background};
  margin-right: 16px;
  svg {
    width: 24px;
    height: 24px;
    path {
      fill: ${({ cardColor, theme }) => iconColors(theme)[cardColor].color};
    }
  }
`

const StyledHeading = styled(Heading)<LinkCardIconStylesProps>`
  color: ${({ cardColor, theme }) => iconColors(theme)[cardColor].color};
`

const StyledTooltip = styled(InfoTooltip)`
  margin-left: 8px;
  path {
    fill: ${({ theme }) => theme.palette.grey.fiftyP};
  }
`

const StyledWarningIcon = styled(WarningIcon)`
  margin-left: 8px;
  width: 20px;
  height: 20px;
  path {
    fill: ${({ theme }) => theme.palette.red.fiftyP};
  }
`
