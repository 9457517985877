import React from "react"
import { Box, Caption } from "kui-basic"
import { FormBottom } from "kui-crm"
import { observer } from "mobx-react"
import useApartmentLiteStore from "../../../../../../../store/shared/apartment"
import { WarningStepProps } from "./types"

const WarningStep = (props: WarningStepProps) => {
  const { handleSubmit } = props
  const { inspectionsStore } = useApartmentLiteStore()
  const formStore = inspectionsStore.creationForm

  return (
    <div data-testid="appraisalWarningStep">
      <Box pr={5} pl={5} pb={3}>
        <Caption weight={500} align="center">
          After the appraisal is created, a blank service contract will be
          created. The owner will have access to the personal account
        </Caption>
      </Box>
      <FormBottom
        onClick={handleSubmit}
        withPrevStep
        handlePrevClick={formStore.prevStep}
        label="Create appraisal"
      />
    </div>
  )
}

export default observer(WarningStep)
