import { makeAutoObservable } from "mobx"
import { Loader, Paginator, SortingFilter } from "kui-utils"
import to from "await-to-js"
import { DateTime } from "luxon"
import { InspectionParams } from "../../types/store/inspectionsStore"
import RegistriesAgent from "../../../../agent/Registries"
import {
  InspectionRegistryModel,
  InspectionsRegistryResponse,
} from "../../types/api/inspections"
import ApartmentsStore from "../../../../store/lites/ApartmentsStore"

class InspectionsRegistryStore {
  inspections: InspectionParams[]

  paginator: Paginator

  filter: SortingFilter

  loader: Loader

  creationLoader: Loader

  constructor() {
    this.inspections = []
    this.paginator = new Paginator()
    this.filter = new SortingFilter("-id")
    this.loader = new Loader()
    this.creationLoader = new Loader()
    makeAutoObservable(this)
  }

  fetchAll = async () => {
    this.loader.startLoading()

    const [err, res] = await to<InspectionsRegistryResponse>(
      RegistriesAgent.getInspectionsRegistry(
        this.paginator.offset,
        this.paginator.limit,
        this.filter.filterParams
      )
    )

    if (res && !err) {
      const mapper = (inspection: InspectionRegistryModel) =>
        InspectionsRegistryStore.getInspectionRegistryParams(inspection)

      this.inspections = this.paginator.getPageResponse<
        InspectionRegistryModel,
        InspectionParams
      >(res, this.inspections, mapper)
    } else {
      this.loader.setError("fetch services", err)
    }
    this.loader.endLoading()
  }

  static getInspectionRegistryParams = (
    inspection: InspectionRegistryModel
  ): InspectionParams => ({
    id: inspection.id,
    apartment: ApartmentsStore.getApartmentLinkParams(inspection.apartment),
    type: inspection.inspection_type,
    date: inspection.date ? DateTime.fromISO(inspection.date) : null,
  })
}

export default InspectionsRegistryStore
