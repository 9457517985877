import React from "react"
import { observer } from "mobx-react"
import { Grid } from "kui-basic"
import styled from "@emotion/styled"
import getContractConditions from "./content"
import usePromotionStore from "../../../../../store"
import PromotionFeature from "../PromotionFeature"
import LinkButton from "../../../../../../../../../components/common/LinkButton"
import { DynamicRoutes } from "../../../../../../../../../types/route"

const ContractConditions = () => {
  const { overviewStore } = usePromotionStore()
  const contractConditions = getContractConditions(
    overviewStore.actualServiceContract
  )
  const contractLink = overviewStore.actualServiceContractId
    ? DynamicRoutes.contract(overviewStore.actualServiceContractId, "service")
    : null

  return (
    <StyledWrapper container alignItems="center">
      {contractConditions.map((item) => (
        <PromotionFeature key={item.label} feature={item} />
      ))}

      {!!contractLink && (
        <StyledButton labelColor="fiftyP" href={contractLink}>
          Open contract
        </StyledButton>
      )}
    </StyledWrapper>
  )
}

export default observer(ContractConditions)

const StyledWrapper = styled(Grid)`
  margin-bottom: 16px;
`

const StyledButton = styled(LinkButton)`
  height: 24px;
  padding: 0 8px !important;
  .KUI-Button_end-icon {
    padding: 0 0 0 4px;
  }
  &:hover > p {
    color: ${({ theme }) => theme.palette.grey.seventy} !important;
  }
`
