import React from "react"
import { HomeIcon } from "kui-icon"
import { addToArrayByCondition } from "kui-utils"
import { Routes } from "../../../../../types/route"
import { LinkCardProps } from "../../../components/common/DashboardLinkCard/types"
import ClosingContractsCard from "../../../components/cards/ClosingContractsCard"
import HousingManagerDashboardStore from "../../../store/variants/HousingManagerDashboardStore"
import InspectionImpairmentsCard from "../../../components/cards/InspectionImpairmentsCard"
import MetersVerificationsCard from "../../../components/cards/MetersVerificationsCard"
import UnsignedInspectionsCard from "../../../components/cards/UnsignedInspectionsCard"
import PendingImpairmentsCard from "../../../components/cards/PendingImpairmentsCard"
import ApartmentsInspectionsCard from "../../../components/cards/ApartmentsInspectionsCard"
import ApartmentInspectionsMap from "../../../components/cards/ApartmentInspectionsMap"

export const getLinkCards = (
  housingManagerDashboard: HousingManagerDashboardStore
): LinkCardProps[] => [
  ...addToArrayByCondition(housingManagerDashboard.overdueProxies !== null, {
    label: "Overdue proxies",
    icon: <HomeIcon />,
    link: `${Routes.proxiesRegistry}?sort=expiration_date`,
    ...housingManagerDashboard.overdueProxies,
  }),
]

export const getListCards = (
  housingManagerDashboard: HousingManagerDashboardStore
) => [
  ...addToArrayByCondition(
    housingManagerDashboard.inspectionsImpairments?.value !== null,
    {
      key: "inspectionsImpairments",
      card: (
        <InspectionImpairmentsCard
          inspectionsStore={housingManagerDashboard.inspectionsImpairments!}
        />
      ),
    }
  ),
  ...addToArrayByCondition(
    housingManagerDashboard.unsignedInspections.value !== null,
    {
      key: "unsignedInspections",
      card: (
        <UnsignedInspectionsCard
          inspectionsStore={housingManagerDashboard.unsignedInspections}
        />
      ),
    }
  ),
  ...addToArrayByCondition(
    housingManagerDashboard.metersVerifications.value !== null,
    {
      key: "metersVerifications",
      card: (
        <MetersVerificationsCard
          metersStore={housingManagerDashboard.metersVerifications}
        />
      ),
    }
  ),
  ...addToArrayByCondition(
    housingManagerDashboard.pendingImpairments.value !== null,
    {
      key: "pendingImpairments",
      card: (
        <PendingImpairmentsCard
          impairmentsStore={housingManagerDashboard.pendingImpairments}
        />
      ),
    }
  ),
  ...addToArrayByCondition(
    housingManagerDashboard.closingRentalContracts.value !== null,
    {
      key: "closingRentalContracts",
      card: (
        <ClosingContractsCard
          contractsStore={housingManagerDashboard.closingRentalContracts}
          {...housingManagerDashboard.closingRentalContracts!}
        />
      ),
    }
  ),
  ...addToArrayByCondition(
    housingManagerDashboard.closingServiceContracts.value !== null,
    {
      key: "closingServiceContracts",
      card: (
        <ClosingContractsCard
          contractsStore={housingManagerDashboard.closingServiceContracts}
        />
      ),
    }
  ),
]

export const getUpcomingInspectionsCards = (
  housingManagerDashboard: HousingManagerDashboardStore
) => [
  ...addToArrayByCondition(
    housingManagerDashboard.settings?.upcomingInspectionsList &&
      housingManagerDashboard.upcomingInspections.value !== null,
    {
      key: "upcomingInspectionsList",
      card: (
        <ApartmentsInspectionsCard
          upcomingInspectionsStore={housingManagerDashboard.upcomingInspections}
        />
      ),
      size: 4,
    }
  ),
  ...addToArrayByCondition(
    housingManagerDashboard.settings?.upcomingInspectionsMap &&
      housingManagerDashboard.upcomingInspections.value !== null,
    {
      key: "upcomingInspectionsMap",
      card: (
        <ApartmentInspectionsMap
          inspections={
            housingManagerDashboard.upcomingInspections?.upcomingInspections ||
            []
          }
        />
      ),
      size: 8,
    }
  ),
]
