import { ContractStatuses } from "kui-crm"

const isContractActive = (status?: ContractStatuses, withPending?: boolean) => {
  if (
    !status ||
    status === "Closed" ||
    status === "Archived" ||
    (!withPending && status === "Pending")
  )
    return false
  return true
}

export default isContractActive
