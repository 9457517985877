import { makeAutoObservable } from "mobx"
import { Loader, Paginator, SortingFilter } from "kui-utils"
import to from "await-to-js"
import RegistriesAgent from "../../../../agent/Registries"
import {
  MeterReadingModel,
  MetersRegistryResponse,
} from "../../types/api/metersReadings"
import MeterReadingStore from "./MeterReadingStore"
import { MeterReadingFormFields } from "../../../../components/forms/meters/MeterReadingForm/types"
import MetersReadingStore from "../../../../store/shared/meters/MetersReadingStore"

class MetersReadingRegistryStore {
  meters: MeterReadingStore[]

  paginator: Paginator

  filter: SortingFilter

  loader: Loader

  creationLoader: Loader

  constructor() {
    this.meters = []
    this.paginator = new Paginator()
    this.filter = new SortingFilter("-id")
    this.loader = new Loader(true)
    this.creationLoader = new Loader()
    makeAutoObservable(this)
  }

  fetchAll = async () => {
    this.loader.startLoading()

    const [err, res] = await to<MetersRegistryResponse>(
      RegistriesAgent.getMetersRegistry(
        this.paginator.offset,
        this.paginator.limit,
        this.filter.filterParams
      )
    )

    if (res && !err) {
      const mapper = (meter: MeterReadingModel) =>
        new MeterReadingStore(meter, this)

      this.meters = this.paginator.getPageResponse<
        MeterReadingModel,
        MeterReadingStore
      >(res, this.meters, mapper)
    } else {
      this.loader.setError("fetch meters verifications", err)
    }
    this.loader.endLoading()
  }

  createMeterReading = async (data: MeterReadingFormFields) => {
    await MetersReadingStore.createMeterReading(
      this.creationLoader,
      data,
      this.addMeterReadingToTable
    )
  }

  addMeterReadingToTable = (meter: MeterReadingModel) => {
    this.meters = [...this.meters, new MeterReadingStore(meter, this)]
  }

  removeMeterReadingFromTable = (id: number) => {
    this.meters = this.meters.filter((meter) => meter.id !== id)
  }
}

export default MetersReadingRegistryStore
