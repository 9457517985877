import React from "react"
import { useToggle } from "kui-utils"
import { Button, Caption, Grid } from "kui-basic"
import { UpdateIcon } from "kui-icon"
import { observer } from "mobx-react"
import styled from "@emotion/styled"
import { ContractClosingButtonsProps } from "./types"
import PopupWithCheckList from "../PopupWithCheckList"
import ContractCheckoutDateModal from "../../modals/ContractCheckoutDateModal"
import ContractClosingModal from "../../modals/ContractClosingModal"
import ProtectedNode from "../ProtectedNode"

const ContractClosingButtons = (props: ContractClosingButtonsProps) => {
  const {
    closeContract,
    setCheckoutDate,
    defaultDate,
    editDate,
    closingProgress,
    canBeClosed,
    label,
    permission,
    resetDate,
  } = props
  const [isCheckOutDateOpen, openCheckOutDate, closeCheckOutDate] = useToggle()
  const [isClosingModalOpen, openClosingModal, closeClosingModal] = useToggle()

  return (
    <>
      <Grid item>
        <Grid container spacing={3}>
          <Grid item>
            <ProtectedNode withTooltip permission={permission}>
              <Button
                onClick={openCheckOutDate}
                variant="orange"
                size="xs"
                endIcon={defaultDate ? <UpdateIcon /> : undefined}
              >
                {defaultDate
                  ? defaultDate.toFormat("dd.MM.yyyy")
                  : `Select ${label} date`}
              </Button>
            </ProtectedNode>
            {editDate && (
              <StyledEditDate size="xs" color="thirty">
                Date set:{" "}
                <StyledAccent>{editDate.toFormat("dd.MM.yyyy")}</StyledAccent>
              </StyledEditDate>
            )}
          </Grid>
          <Grid item>
            <PopupWithCheckList
              label="To close a contract you need:"
              items={closingProgress}
              popupActive={!canBeClosed}
              button={
                <ProtectedNode withTooltip permission={permission}>
                  <Button
                    onClick={openClosingModal}
                    size="xs"
                    disabled={!canBeClosed}
                  >
                    Close contract
                  </Button>
                </ProtectedNode>
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <ContractCheckoutDateModal
        open={isCheckOutDateOpen}
        handleClose={closeCheckOutDate}
        defaultDate={defaultDate}
        setCheckoutDate={setCheckoutDate}
        label={label}
        resetDate={resetDate}
      />
      <ContractClosingModal
        open={isClosingModalOpen}
        handleClose={closeClosingModal}
        closeContract={closeContract}
      />
    </>
  )
}

export default observer(ContractClosingButtons)

const StyledEditDate = styled(Caption)`
  margin-top: 6px;
  text-align: center;
`

const StyledAccent = styled.span`
  color: ${({ theme }) => theme.palette.grey.fiftyP};
`
