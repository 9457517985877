import { DateTime } from "luxon"
import to from "await-to-js"
import { makeAutoObservable } from "mobx"
import { Loader } from "kui-utils"
import UserLiteStore from "../../../../../store/templates/UserLite"
import {
  ApartmentExpensesPeriodModel,
  ContractPeriodParams,
  ExpensesPeriodClosingConditions,
} from "../types/api/expensesPeriodAPI"
import ApartmentExpensesAgent from "../../../../../agent/ApartmentExpenses"
import ApartmentExpensesStore from "./ApartmentExpenses"
import { ClosePeriodFormFields } from "../forms/summary/ClosePeriodForm/types"
import { uploadNewFile } from "../../../../../utils/agent/uploadFiles"
import { CheckParams } from "../../../../../types/common"
import { closingConditionLabel } from "../../../../../utils/content/values"
import { PeriodContractParams } from "../types/store/expensesPeriod"

class ApartmentExpensesPeriod {
  id: number | null

  closingDate: DateTime | null

  closedBy: UserLiteStore | null

  isClosed: boolean

  actionLoader: Loader

  expensesStore: ApartmentExpensesStore

  withInitialCalculation: boolean

  isPrevPeriodsClosed: boolean

  isLastMonthOfContract: boolean

  isDepositCalculated: boolean

  hasViolationsOfTermination: boolean

  landlord: UserLiteStore | null

  tenant: UserLiteStore | null

  closingProgress: CheckParams[]

  rentalContract: PeriodContractParams | null

  serviceContract: PeriodContractParams | null

  constructor(
    periodInfo: ApartmentExpensesPeriodModel,
    expensesStore: ApartmentExpensesStore
  ) {
    this.actionLoader = new Loader()
    this.expensesStore = expensesStore

    this.id = periodInfo.id
    this.closingDate = periodInfo.close_datetime
      ? DateTime.fromISO(periodInfo.close_datetime)
      : null
    this.closedBy = periodInfo.who_close
      ? UserLiteStore.initFromLiteUserModel(periodInfo.who_close)
      : null
    this.isClosed = periodInfo.status === "closed"
    this.isPrevPeriodsClosed = !!periodInfo.id
    this.isLastMonthOfContract = !!periodInfo.is_last_for_rental_contract
    this.hasViolationsOfTermination = !!periodInfo.has_violations_of_termination
    this.landlord = periodInfo.owner
      ? UserLiteStore.initFromLiteUserModel(periodInfo.owner)
      : null
    this.tenant = periodInfo.renter
      ? UserLiteStore.initFromLiteUserModel(periodInfo.renter)
      : null
    this.rentalContract = ApartmentExpensesPeriod.getContractParams(
      periodInfo.rental_contract
    )
    this.serviceContract = ApartmentExpensesPeriod.getContractParams(
      periodInfo.service_contract
    )
    this.isDepositCalculated = periodInfo.is_deposit_calculated
    if (periodInfo.closing_conditions) {
      this.closingProgress = ApartmentExpensesPeriod.getClosingProgress(
        periodInfo.closing_conditions
      )
    } else this.closingProgress = []
    this.withInitialCalculation = periodInfo.is_initial_calculation
    makeAutoObservable(this)
  }

  cleanPeriodId = () => {
    this.id = null
  }

  closePeriod = async (apartmentId: number, data: ClosePeriodFormFields) => {
    if (this.id) {
      this.actionLoader.startLoading("period closing")

      const file = await uploadNewFile(this.actionLoader, data.paymentOrder)
      const body = { payout_order_document: file }

      const [err, res] = await to<ApartmentExpensesPeriodModel>(
        ApartmentExpensesAgent.closePeriod(apartmentId, this.id, body)
      )

      if (res && !err) {
        this.updatePeriodInfo(res)
      } else {
        this.actionLoader.setError("period closing", err)
      }
      this.actionLoader.endLoading()
    }
  }

  updatePeriodInfo = (periodInfo: ApartmentExpensesPeriodModel) => {
    this.closingDate = periodInfo.close_datetime
      ? DateTime.fromISO(periodInfo.close_datetime)
      : null
    this.closedBy = periodInfo.who_close
      ? UserLiteStore.initFromLiteUserModel(periodInfo.who_close)
      : null
    this.isClosed = periodInfo.status === "closed"
    this.isPrevPeriodsClosed = !!periodInfo.id
    this.isLastMonthOfContract = !!periodInfo.is_last_for_rental_contract
    this.isDepositCalculated = periodInfo.is_deposit_calculated
    if (this.expensesStore.lastOpenPeriod) {
      this.expensesStore.setLastOpenPeriod(
        this.expensesStore.lastOpenPeriod.plus({ month: 1 })
      )
    }
    this.withInitialCalculation = periodInfo.is_initial_calculation
  }

  setDepositCalculated = () => {
    this.isDepositCalculated = true
  }

  setWithInitialCalculation = (value: boolean) => {
    this.withInitialCalculation = value
  }

  setExpensesWarnings = (
    hasTerminationWarning: boolean,
    hasPeriodWarning: boolean
  ) => {
    this.hasViolationsOfTermination = hasTerminationWarning
    this.isPrevPeriodsClosed = !hasPeriodWarning
  }

  get formattedClosingDate() {
    const isPeriodClosedToday =
      Math.abs(Number(this.closingDate?.diffNow(["hours"]).toObject().hours)) <
      24

    return isPeriodClosedToday
      ? this.closingDate?.setLocale("en").toRelative()
      : this.closingDate?.toFormat("dd.MM.yyyy")
  }

  get canBeEdited() {
    return this.isPrevPeriodsClosed && !this.isClosed
  }

  get canBeClosed() {
    return (
      this.closingProgress.filter((step) => !step.checked).length === 0 ||
      !this.isLastMonthOfContract
    )
  }

  get contractInfo() {
    return this.rentalContract || this.serviceContract
  }

  static getClosingProgress = (fields: ExpensesPeriodClosingConditions) =>
    Object.keys(fields).map((condition) => ({
      label:
        closingConditionLabel[condition as keyof typeof closingConditionLabel],
      checked: !!fields[condition as keyof typeof fields],
    }))

  static getContractParams = (contract: ContractPeriodParams | null) => {
    if (!contract) return null

    return {
      id: contract.id,
      number: contract.number,
      startDate: contract.start_date
        ? DateTime.fromISO(contract.start_date)
        : null,
      endDate: contract.end_date ? DateTime.fromISO(contract.end_date) : null,
    }
  }
}

export default ApartmentExpensesPeriod
