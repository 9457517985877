import { makeAutoObservable } from "mobx"
import { Loader, Paginator, SortingFilter } from "kui-utils"
import to from "await-to-js"
import { DateTime } from "luxon"
import { getInspectionFeedbackStatus } from "kui-crm"
import { TenantAppraisalParams } from "../../types/store/appraisalsStore"
import RegistriesAgent from "../../../../agent/Registries"
import {
  TenantAppraisalModel,
  TenantAppraisalsRegistryResponse,
} from "../../types/api/appraisals"
import ApartmentsStore from "../../../../store/lites/ApartmentsStore"

class TenantAppraisalsRegistryStore {
  appraisals: TenantAppraisalParams[]

  paginator: Paginator

  filter: SortingFilter

  loader: Loader

  creationLoader: Loader

  constructor() {
    this.appraisals = []
    this.paginator = new Paginator()
    this.filter = new SortingFilter("-id")
    this.loader = new Loader()
    this.creationLoader = new Loader()
    makeAutoObservable(this)
  }

  fetchAll = async () => {
    this.loader.startLoading()

    const [err, res] = await to<TenantAppraisalsRegistryResponse>(
      RegistriesAgent.getTenantAppraisalsRegistry(
        this.paginator.offset,
        this.paginator.limit,
        this.filter.filterParams
      )
    )

    if (res && !err) {
      const mapper = (appraisal: TenantAppraisalModel) =>
        TenantAppraisalsRegistryStore.getAppraisalParams(appraisal)

      this.appraisals = this.paginator.getPageResponse<
        TenantAppraisalModel,
        TenantAppraisalParams
      >(res, this.appraisals, mapper)
    } else {
      this.loader.setError("fetch appraisals", err)
    }
    this.loader.endLoading()
  }

  static getAppraisalParams = (
    appraisal: TenantAppraisalModel
  ): TenantAppraisalParams => ({
    id: appraisal.id,
    apartment: ApartmentsStore.getApartmentLinkParams(appraisal.apartment),
    contract: appraisal.rental_contract
      ? {
          id: appraisal.rental_contract.id,
          number: appraisal.rental_contract.number,
        }
      : null,
    creationDate: appraisal.creation_date
      ? DateTime.fromISO(appraisal.creation_date)
      : null,
    status: appraisal.conclusion
      ? getInspectionFeedbackStatus(appraisal.conclusion)
      : null,
    offer:
      appraisal.conclusion &&
      appraisal.conclusion.agree &&
      appraisal.conclusion.signed
        ? {
            date: DateTime.fromISO(appraisal.conclusion.new_price_valid_from),
            deltaPrice: Number(appraisal.conclusion.price_change),
            deltaPricePercent: Number(
              appraisal.conclusion.price_change_percent
            ),
          }
        : null,
  })
}

export default TenantAppraisalsRegistryStore
