import React from "react"
import {
  AnimalIcon,
  ChildIcon,
  NoAnumalIcon,
  NoChildIcon,
  NoSmokingIcon,
  SmokingIcon,
} from "kui-icon"
import { PromotionFeatureParams } from "../PromotionFeature/types"
import ServiceContractLiteStore from "../../../../../../../../../store/templates/ServiceContractLite"

const getContractConditions = (
  contract?: ServiceContractLiteStore | null
): PromotionFeatureParams[] => [
  {
    label: "With children",
    icon: <ChildIcon />,
    oppositeIcon: <NoChildIcon />,
    oppositeLabel: "Without children",
    value: contract?.withChildren,
  },
  {
    label: "With pets",
    icon: <AnimalIcon />,
    oppositeIcon: <NoAnumalIcon />,
    oppositeLabel: "Without pets",
    value: contract?.withPets,
  },
  {
    label: "Smoking allowed",
    icon: <SmokingIcon />,
    oppositeIcon: <NoSmokingIcon />,
    oppositeLabel: "No smoking",
    value: contract?.smokingAllowed,
  },
]

export default getContractConditions
