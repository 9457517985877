import { makeAutoObservable } from "mobx"
import { getPhoneParamsFromString, Loader, resHandler } from "kui-utils"
import to from "await-to-js"
import { ClientLiteModel } from "kui-crm"
import { HousingInspectorDashboardSettings } from "../../types/store/housingInspectorDashboard"
import DashboardPageStore from "../DashboardPageStore"
import { DashboardSettingsModel } from "../../../../types/api/dashboard"
import DashboardAgent from "../../../../agent/Dashboard"
import UnsignedInspectionsStore from "../entities/UnsignedInspectionsStore"
import { DashboardStoreInterface } from "../../types/store/common"
import { UserRole } from "../../../../types/api/cabinet"
import UpcomingInspectionsStore from "../entities/UpcomingInspectionsStore"

class HousingInspectorDashboardStore implements DashboardStoreInterface {
  upcomingInspections: UpcomingInspectionsStore

  unsignedInspections: UnsignedInspectionsStore

  settings: HousingInspectorDashboardSettings | null

  loader: Loader

  dashboardStore: DashboardPageStore

  role: UserRole

  constructor(dashboardStore: DashboardPageStore) {
    this.upcomingInspections = new UpcomingInspectionsStore(dashboardStore)
    this.unsignedInspections = new UnsignedInspectionsStore(dashboardStore)
    this.settings = null
    this.loader = new Loader(true)
    this.dashboardStore = dashboardStore
    this.role = "housing_inspector"
    makeAutoObservable(this)
  }

  initDashboard = async () => {
    this.loader.startLoading()

    await this.fetchSettings()
    await this.updateCards()

    this.loader.endLoading()
  }

  loadCards = async () => {
    this.loader.startLoading()

    this.resetCards()
    await this.updateCards()

    this.loader.endLoading()
  }

  updateCards = async () => {
    if (this.settings) {
      const promises = []
      if (
        this.settings.upcomingInspectionsList ||
        this.settings.upcomingInspectionsMap
      ) {
        promises.push(this.upcomingInspections.fetchApartmentsInspections())
      }
      if (this.settings.unsignedInspections) {
        promises.push(this.unsignedInspections.fetchInspections())
      }

      await Promise.allSettled(promises)
    }
  }

  fetchSettings = async () => {
    this.loader.startLoading()

    const response = await to<DashboardSettingsModel>(
      DashboardAgent.getSettings()
    )

    resHandler(response, this.loader, this.updateSettings)
  }

  editSettings = async (settings: HousingInspectorDashboardSettings) => {
    this.loader.startLoading()

    const body =
      HousingInspectorDashboardStore.getDashboardSettingsBody(settings)
    const response = await to(DashboardAgent.editSettings(body))

    resHandler(response, this.loader, this.updateSettings, "update settings", {
      withEndLoading: false,
    })

    this.resetCards()
    await this.updateCards()

    this.loader.endLoading()
  }

  updateSettings = (settings: DashboardSettingsModel) => {
    const { visible_blocks } = settings
    this.settings = {
      upcomingInspectionsList: !!visible_blocks.upcoming_inspections,
      upcomingInspectionsMap: !!visible_blocks.upcoming_inspections_map,
      unsignedInspections: !!visible_blocks.unsigned_inspections,
    }
  }

  resetSettings = () => {
    this.settings = null
  }

  resetCards = () => {
    this.upcomingInspections = new UpcomingInspectionsStore(this.dashboardStore)
    this.unsignedInspections = new UnsignedInspectionsStore(this.dashboardStore)
  }

  resetDashboard = () => {
    this.resetSettings()
    this.resetCards()
  }

  static getDashboardSettingsBody = (
    settings: HousingInspectorDashboardSettings
  ): DashboardSettingsModel => ({
    visible_blocks: {
      upcoming_inspections: settings.upcomingInspectionsList,
      upcoming_inspections_map: settings.upcomingInspectionsMap,
      unsigned_inspections: settings.unsignedInspections,
    },
  })

  static getClientParams = (client: ClientLiteModel | null) => {
    if (!client) return null

    return {
      id: client.id,
      phoneNumber: getPhoneParamsFromString(client.phone),
      fullName: client.full_name,
    }
  }
}

export default HousingInspectorDashboardStore
