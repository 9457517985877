import React from "react"
import styled from "@emotion/styled"
import { Caption, Grid } from "kui-basic"
import { WarningIcon } from "kui-icon"

const ClosePeriodWarning = () => (
  <StyledWrapper
    data-testid="closePeriodWarning"
    container
    alignItems="center"
    wrap="nowrap"
  >
    <StyledIcon />
    <Caption size="s">
      After closing the period, initial calculation will be deleted
    </Caption>
  </StyledWrapper>
)

export default ClosePeriodWarning

const StyledWrapper = styled(Grid)`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.palette.red.fiftyP};
  padding: 8px 12px;
  margin-bottom: 16px;
`

const StyledIcon = styled(WarningIcon)`
  margin-right: 8px;
  path {
    fill: ${({ theme }) => theme.palette.red.fiftyP};
  }
`
