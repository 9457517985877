import { HomeIcon } from "kui-icon"
import React from "react"
import styled from "@emotion/styled"
import { observer } from "mobx-react"
import DashboardListCard from "../../common/DashboardListCard"
import { ApartmentsInspectionsCardProps } from "./types"
import ApartmentInspectionRow from "./ApartmentInspectionRow"
import CardHeaderWithDone from "../../common/CardHeaderWithDone"

const ApartmentsInspectionsCard = (props: ApartmentsInspectionsCardProps) => {
  const { upcomingInspectionsStore } = props
  const { value, done, upcomingInspections } = upcomingInspectionsStore

  return (
    <StyledCard
      value={value}
      label="Upcoming inspections"
      icon={<HomeIcon />}
      customHeader={
        <CardHeaderWithDone
          value={value}
          done={done}
          valueLabel="Upcoming inspections"
          doneLabel="Completed inspections"
        />
      }
    >
      {upcomingInspections.map((inspection) => (
        <ApartmentInspectionRow key={inspection.id} inspection={inspection} />
      ))}
    </StyledCard>
  )
}

export default observer(ApartmentsInspectionsCard)

const StyledCard = styled(DashboardListCard)`
  .PaginationList {
    max-height: 346px;
  }
`
