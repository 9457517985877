import { addToArrayByCondition } from "kui-utils"
import React from "react"
import HousingInspectorDashboardStore from "../../../store/variants/HousingInspectorDashboardStore"
import ApartmentsInspectionsCard from "../../../components/cards/ApartmentsInspectionsCard"
import ApartmentInspectionsMap from "../../../components/cards/ApartmentInspectionsMap"
import UnsignedInspectionsCard from "../../../components/cards/UnsignedInspectionsCard"

export const getListCards = (
  housingInspectorStore: HousingInspectorDashboardStore
) => [
  ...addToArrayByCondition(
    housingInspectorStore.settings?.upcomingInspectionsList &&
      housingInspectorStore.upcomingInspections.value !== null,
    {
      key: "upcomingInspectionsList",
      card: (
        <ApartmentsInspectionsCard
          upcomingInspectionsStore={housingInspectorStore.upcomingInspections}
        />
      ),
      size: 4,
    }
  ),
  ...addToArrayByCondition(
    housingInspectorStore.settings?.upcomingInspectionsMap &&
      housingInspectorStore.upcomingInspections.value !== null,
    {
      key: "upcomingInspectionsMap",
      card: (
        <ApartmentInspectionsMap
          inspections={
            housingInspectorStore.upcomingInspections?.upcomingInspections || []
          }
        />
      ),
      size: 8,
    }
  ),
  ...addToArrayByCondition(
    housingInspectorStore.unsignedInspections.value !== null,
    {
      key: "unsignedInspections",
      card: (
        <UnsignedInspectionsCard
          inspectionsStore={housingInspectorStore.unsignedInspections}
        />
      ),
    }
  ),
]
