import React from "react"
import { observer } from "mobx-react"
import { Box } from "kui-basic"
import ExpensesContracts from "../ExpensesContracts"
import ExpensesCalendar from "../ExpensesCalendar"
import useExpensesStore from "../../../store"
import { ExpensesTabsSettingsProps } from "./types"
import ServiceCreationButton from "../../tabs/ExpensesServicesTab/ServiceCreationButton"
import ExpensesWarning from "../ExpensesWarning"
import { periodWarning, violationsOfTerminationWarning } from "./content"
import ApartmentExpensesPeriod from "../../../store/ApartmentExpensesPeriod"

const getWarningMessage = (selectedPeriod: ApartmentExpensesPeriod | null) => {
  if (selectedPeriod?.hasViolationsOfTermination)
    return violationsOfTerminationWarning
  if (!selectedPeriod?.isPrevPeriodsClosed) return periodWarning
  return ""
}

function ExpensesTabsSettings({ activeTab }: ExpensesTabsSettingsProps) {
  const { periodsStore } = useExpensesStore()
  const { selectedPeriod, availablePeriodsOptions } = periodsStore
  const warningMessage = getWarningMessage(selectedPeriod)

  return (
    <>
      {!!warningMessage && <ExpensesWarning message={warningMessage} />}
      {activeTab === 2 && <ServiceCreationButton />}
      {availablePeriodsOptions.length > 1 && (
        <Box mr={2}>
          <ExpensesContracts />
        </Box>
      )}
      <ExpensesCalendar />
    </>
  )
}

export default observer(ExpensesTabsSettings)
