import React from "react"
import styled from "@emotion/styled"
import { Button } from "kui-basic"
import { Link } from "kui-crm"
import { AttachmentIcon, CloseIcon } from "kui-icon"
import { SquareFileBilletProps } from "./types"

function SquareFileBillet(props: SquareFileBilletProps) {
  const { link, size, onDelete } = props

  return (
    <StyledWrapper>
      <Link href={link} openInNewWindow>
        <StyledButton isCircle size={size || "m"} variant="whiteWithGray">
          <AttachmentIcon />
        </StyledButton>
      </Link>
      {!!onDelete && (
        <StyledRemoveButton isCircle variant="whiteWithGray" onClick={onDelete}>
          <CloseIcon width={8} height={8} />
        </StyledRemoveButton>
      )}
    </StyledWrapper>
  )
}

export default SquareFileBillet

const StyledWrapper = styled.div`
  position: relative;
  &:hover {
    > button {
      opacity: 1;
    }
  }
`

const StyledButton = styled(Button)`
  width: 48px;
  height: 48px;
  border-radius: 8px !important;
  overflow: hidden;
  padding: 10px 8px !important;
  text-align: left;
`

const StyledRemoveButton = styled(Button)`
  width: 16px;
  height: 16px;
  position: absolute;
  top: -4px;
  right: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all ease-out 0.3s;
`
