import to from "await-to-js"
import { makeAutoObservable, runInAction } from "mobx"
import { DateTime } from "luxon"
import { Loader, resHandler, toNumber } from "kui-utils"
import {
  ChangeReasonFormFields,
  FileBodyRequest,
  ApartmentMeterAccounts,
  ApartmentDocumentModel,
  ApartmentInspectionModel,
  ApartmentLockerParams,
  ApartmentModel,
} from "kui-crm"
import NotesStore from "../../../store/notes/Notes"
import {
  ApartmentDocumentVariants,
  CompanyLiteParams,
} from "../types/store/apartment"
import {
  ApartmentOverviewInfoFields,
  ApartmentOverviewPaymentInfoFields,
} from "../forms/overview/ApartmentOverviewInfoForm/types"
import {
  AddObjectsToCompanyRequest,
  GetCompanyObjectsResponse,
} from "../../CompanyPage/types/api/companyAPI"
import CompanyAgent from "../../../agent/Company"
import ApartmentPageStore from "./ApartmentPageStore"
import UserLiteStore from "../../../store/templates/UserLite"
import {
  ApartmentDocumentParams,
  InspectionParams,
  MaxCountMetersParams,
} from "../../../types/store/apartments"
import RentalContractLiteStore from "../../../store/templates/RentalContractLite"
import ServiceContractLiteStore from "../../../store/templates/ServiceContractLite"
import {
  getPatchFileParams,
  uploadNewFile,
} from "../../../utils/agent/uploadFiles"
import FileStore from "../../../store/templates/File"
import RegistriesAgent from "../../../agent/Registries"
import { ApartmentDocumentFields } from "../../../components/forms/apartment/ApartmentDocumentForm/types"
import ApartmentOverviewAgent from "../../../agent/ApartmentOverview"
import DocumentsWithPaginatorStore from "../../../store/templates/DocumentsWithPaginatorStore"
import { archivedObjectDisabledReason } from "../../../utils/content/constants"
import isContractActive from "../../../utils/service/isContractActive"

class ApartmentOverviewStore {
  id: number | null = null

  address: string = ""

  objectName: string = ""

  entrance: string = ""

  doorCode: string = ""

  floor: number | null = null

  floorCount: number | null = null

  previewImage: string | null = null

  isImported: boolean = false

  zipCode: string = ""

  rentalContracts: RentalContractLiteStore[] = []

  serviceContracts: ServiceContractLiteStore[] = []

  locker: ApartmentLockerParams | null = null

  folderNumber: string = ""

  communalServicesAccount: string = ""

  payerCode: string = ""

  managementCompany: CompanyLiteParams | null = null

  landlord: UserLiteStore | null = null

  tenant: UserLiteStore | null = null

  maxMetersCount: MaxCountMetersParams | null = null

  notesStore: NotesStore | null = null

  inspection: InspectionParams | null = null

  insurance: ApartmentDocumentParams | null = null

  proxy: ApartmentDocumentParams | null = null

  loader: Loader

  actionLoader: Loader

  apartmentStore: ApartmentPageStore

  operatingAccounts: ApartmentMeterAccounts | null = null

  documentsStore: DocumentsWithPaginatorStore

  status: string | null = null

  constructor(apartmentStore: ApartmentPageStore) {
    this.apartmentStore = apartmentStore
    this.loader = new Loader()
    this.actionLoader = new Loader()
    this.documentsStore = new DocumentsWithPaginatorStore(
      ApartmentOverviewAgent
    )
    makeAutoObservable(this)
  }

  initOverviewTab = async () => {
    if (this.id) {
      this.loader.startLoading()
      await this.documentsStore.fetchDocuments(this.id)
      this.loader.endLoading()
    }
  }

  patchOverviewInfo = async (
    apartmentId: number,
    data: ApartmentOverviewInfoFields
  ) => {
    await Promise.allSettled([
      this.changeOverviewInfo(apartmentId, data),
      this.changeApartmentCompany(apartmentId, data.paymentInfo),
      this.documentsStore.patchDocuments(data.documents, apartmentId),
    ])
  }

  changeOverviewInfo = async (
    apartmentId: number,
    data: ApartmentOverviewInfoFields
  ) => {
    const response = await this.apartmentStore.patchApartment(apartmentId, data)

    resHandler(
      response,
      this.loader,
      this.updateOverviewInfo,
      "patch apartment"
    )
  }

  changeApartmentCompany = async (
    apartmentId: number,
    data: ApartmentOverviewPaymentInfoFields
  ) => {
    const oldCompanyId = this.managementCompany?.id
    if (oldCompanyId && oldCompanyId !== data.company?.id) {
      await this.removeCompanyFromApartment(apartmentId, oldCompanyId)
    }
    if (data.company && oldCompanyId !== data.company?.id) {
      await this.addCompanyToApartment(apartmentId, data)
    }
  }

  addCompanyToApartment = async (
    apartmentId: number,
    data: ApartmentOverviewPaymentInfoFields
  ) => {
    const { company } = data
    const reasonFile = await uploadNewFile(this.loader, data.reason)
    if (company && reasonFile) {
      const body = ApartmentOverviewStore.getAddingCompanyBody(
        data,
        reasonFile,
        apartmentId
      )

      const [err] = await to<GetCompanyObjectsResponse>(
        CompanyAgent.addObjectsToCompany(company.id, body)
      )

      runInAction(() => {
        if (!err) {
          this.managementCompany = company
        } else {
          this.loader.setError("company add", err)
        }
      })
    }
  }

  removeCompanyFromApartment = async (
    apartmentId: number,
    companyId: number
  ) => {
    const body = { apartments: [apartmentId] }

    const [err] = await to<GetCompanyObjectsResponse>(
      CompanyAgent.deleteObjectsToCompany(companyId, body)
    )

    runInAction(() => {
      if (err) this.loader.setError("company removal", err)
      else this.managementCompany = null
    })
  }

  addApartmentDocument = async (
    variant: ApartmentDocumentVariants,
    data: ApartmentDocumentFields
  ) => {
    if (this.id) {
      this.actionLoader.startLoading()
      const apiEndpoint = variant === "insurance" ? "insurances" : "proxies"

      const file = await uploadNewFile(this.actionLoader, data.file)
      if (file) {
        const body = ApartmentOverviewStore.getApartmentDocumentBody(
          this.id,
          data,
          file
        )

        const response = await to<ApartmentDocumentModel>(
          RegistriesAgent.createDocument(apiEndpoint, body)
        )

        resHandler(response, this.actionLoader, (res) => {
          this.updateApartmentDocument(variant, res)
        })
      } else {
        this.actionLoader.endLoading()
      }
    }
  }

  editApartmentDocument = async (
    variant: ApartmentDocumentVariants,
    data: ApartmentDocumentFields
  ) => {
    if (this.id) {
      this.actionLoader.startLoading()
      const apiEndpoint = variant === "insurance" ? "insurances" : "proxies"

      const updatedFile = getPatchFileParams(data.file)
      const newFile = await uploadNewFile(this.actionLoader, data.file)
      const file = newFile || updatedFile

      if (file) {
        const body = ApartmentOverviewStore.getApartmentDocumentBody(
          this.id,
          data,
          file
        )

        const response = await to<ApartmentDocumentModel>(
          RegistriesAgent.editDocument(data.id, apiEndpoint, body)
        )

        resHandler(response, this.actionLoader, (res) => {
          this.updateApartmentDocument(variant, res)
        })
      } else {
        this.actionLoader.endLoading()
      }
    }
  }

  removeApartmentDocument = async (variant: ApartmentDocumentVariants) => {
    const id = this[variant]?.id
    const apiEndpoint = variant === "insurance" ? "insurances" : "proxies"

    if (id) {
      this.actionLoader.startLoading()

      const response = await to(RegistriesAgent.removeDocument(id, apiEndpoint))

      resHandler(response, this.actionLoader, (res) => {
        this.clearApartmentDocument(variant)
      })
    }
  }

  updatePreviewImage = (previewImage: string | null) => {
    this.previewImage = previewImage
  }

  updateApartmentDocument = (
    variant: ApartmentDocumentVariants,
    data: ApartmentDocumentModel
  ) => {
    const document = ApartmentOverviewStore.getDocumentParams(data)
    if (variant === "insurance") this.insurance = document
    else this.proxy = document
  }

  clearApartmentDocument = (variant: ApartmentDocumentVariants) => {
    if (variant === "insurance") this.insurance = null
    else this.proxy = null
  }

  updateOverviewInfo = (apartment: ApartmentModel) => {
    this.id = apartment.id
    this.address = apartment.address
    this.objectName = apartment.object_name || apartment.address
    this.entrance = apartment.num_entrance
    this.doorCode = apartment.intercom_code
    this.floor = apartment.floor
    this.floorCount = apartment.floor_count
    this.previewImage = apartment.main_image?.image_small_url || null
    this.isImported = !!apartment.is_imported_from_old_crm
    this.zipCode = apartment.post_index || ""
    this.rentalContracts = apartment.rental_contracts?.map(
      (rentalContract) => new RentalContractLiteStore(rentalContract)
    )
    this.serviceContracts = apartment.service_contracts?.map(
      (serviceContract) => new ServiceContractLiteStore(serviceContract)
    )
    this.notesStore = new NotesStore("apartments", apartment.id)
    this.locker = apartment.locker || null
    this.folderNumber = apartment.folder_number
    this.payerCode = apartment.payer_code
    this.communalServicesAccount = apartment.financial_personal_account
    this.managementCompany = apartment.administrative_company
      ? {
          ...apartment.administrative_company,
          operatingAccount: apartment.payer_code,
        }
      : null
    this.landlord = apartment.owner
      ? UserLiteStore.initFromLiteUserModel(apartment.owner, "landlord")
      : null
    this.tenant = apartment.renter
      ? UserLiteStore.initFromLiteUserModel(apartment.renter, "tenant")
      : null
    this.inspection = ApartmentOverviewStore.getInspectionParams(
      apartment.inspection
    )
    this.maxMetersCount = {
      ...(apartment.max_gas_counters && { gas: apartment.max_gas_counters }),
      ...(apartment.max_electricity_counters && {
        electricity: apartment.max_electricity_counters,
      }),
      ...(apartment.max_heating_counters && {
        heating: apartment.max_heating_counters,
      }),
      ...(apartment.max_water_counters && {
        water: apartment.max_water_counters,
      }),
    }
    this.operatingAccounts = apartment.operating_accounts || null
    this.proxy = ApartmentOverviewStore.getDocumentParams(
      apartment.proxy_document
    )
    this.insurance = ApartmentOverviewStore.getDocumentParams(
      apartment.insurance_document
    )
    this.status = apartment.rental_status

    if (this.status === "Archived") {
      this.apartmentStore.setDisabledReason(archivedObjectDisabledReason)
    } else {
      this.apartmentStore.setDisabledReason("")
    }
  }

  setStatus = (status: string) => {
    this.status = status
  }

  get startDate() {
    return this.serviceContracts[0]?.startDate || DateTime.now()
  }

  get actualRentalContract() {
    return this.rentalContracts.find((contract) =>
      isContractActive(contract.status)
    )
  }

  get notClosedRentalContract() {
    return this.rentalContracts.find((contract) =>
      isContractActive(contract.status, true)
    )
  }

  get actualRentalContractId() {
    return this.actualRentalContract?.id ?? null
  }

  get actualServiceContract() {
    return this.serviceContracts.find((contract) =>
      isContractActive(contract.status, true)
    )
  }

  get actualServiceContractId() {
    return (
      this.serviceContracts.find((contract) =>
        isContractActive(contract.status)
      )?.id ?? null
    )
  }

  get isFirstFloor() {
    return this.floor === 1
  }

  get isLastFloor() {
    return this.floor === this.floorCount
  }

  static getAddingCompanyBody = (
    data: ChangeReasonFormFields,
    reasonFile: FileBodyRequest,
    apartment: number
  ) =>
    ({
      apartments: [apartment],
      comment: data.comment,
      reason_document: reasonFile,
    } as AddObjectsToCompanyRequest)

  static getInspectionParams = (inspection: ApartmentInspectionModel) => ({
    nextInspectionDate: inspection.next_inspection
      ? DateTime.fromISO(inspection.next_inspection)
      : null,
    inspectionsInterval: inspection.inspection_interval_month,
    minPrice: inspection.min_price,
    maxPrice: inspection.max_price,
  })

  static getDocumentParams = (document?: ApartmentDocumentModel) => {
    if (!document) return null
    return {
      id: document.id,
      number: document.number,
      price: document.amount ? Number(document.amount) : undefined,
      file: document.document
        ? FileStore.initFromDocumentModel(document.document)
        : null,
      startDate: document.start_date
        ? DateTime.fromISO(document.start_date)
        : null,
      endDate: document.expiration_date
        ? DateTime.fromISO(document.expiration_date)
        : null,
    }
  }

  static getApartmentDocumentBody = (
    apartmentId: number,
    document: ApartmentDocumentFields,
    file: FileBodyRequest
  ) => ({
    apartment: apartmentId,
    document: file,
    expiration_date: document.endDate?.toISODate(),
    start_date: document.startDate?.toISODate(),
    number: document.number,
    amount: toNumber(document.price),
  })
}

export default ApartmentOverviewStore
