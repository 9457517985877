import requests from "./index"
import {
  ClosePeriodRequest,
  PatchApartmentPaymentModel,
  PostApartmentPaymentModel,
} from "../pages/ApartmentPage/pages/ExpensesPage/types/api/expensesSummaryAPI"
import { CalendarStartDateModel } from "../pages/ApartmentPage/pages/ExpensesPage/types/api/expensesPeriodAPI"

const ApartmentExpensesAgent = {
  getPaymentSummary: (
    apartmentId: number,
    contractId: number | undefined,
    periodId: number
  ) =>
    requests.get(
      `/apartments/${apartmentId}/expenses/bill-periods/${periodId}/summary${
        contractId ? `?rental_contract=${contractId}` : ""
      }`
    ),
  getPeriods: (apartmentId: number, year: number, month: number) =>
    requests.get(
      `/apartments/${apartmentId}/expenses/bill-periods?year=${year}&month=${month}`
    ),
  getStartDate: (apartmentId: number) =>
    requests.get<CalendarStartDateModel>(
      `/apartments/${apartmentId}/expenses/calendar-start-date`
    ),
  closePeriod: (
    apartmentId: number,
    periodId: number,
    body: ClosePeriodRequest
  ) =>
    requests.post(
      `/apartments/${apartmentId}/expenses/bill-periods/${periodId}/close`,
      body
    ),
  createPayment: (apartmentId: number, data: PostApartmentPaymentModel) =>
    requests.post(`/apartments/${apartmentId}/expenses/payments`, data),
  patchPayment: (
    apartmentId: number,
    paymentId: number,
    data: PatchApartmentPaymentModel
  ) =>
    requests.patch(
      `/apartments/${apartmentId}/expenses/payments/${paymentId}`,
      data
    ),
  deletePayment: (apartmentId: number, paymentId: number) =>
    requests.delete(
      `/apartments/${apartmentId}/expenses/payments/${paymentId}`
    ),
  getBillLinesById: (
    apartmentId: number,
    contractId: number | undefined,
    periodId: number
  ) =>
    requests.get(
      `/apartments/${apartmentId}/expenses/bill-periods/${periodId}/lines${
        contractId ? `?rental_contract=${contractId}` : ""
      }`
    ),
  getAgentReportPDF: (apartmentId: number, year: number, month: number) =>
    requests.get(
      `/apartments/${apartmentId}/expenses/agent-reports?year=${year}&month=${month}`
    ),
  calcDeposit: (apartmentId: number) =>
    requests.post(`/apartments/${apartmentId}/expenses/deposit-file/calculate`),
}

export default ApartmentExpensesAgent
