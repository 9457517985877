import React from "react"
import { Box } from "kui-basic"
import { observer } from "mobx-react"
import styled from "@emotion/styled"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { LoaderState } from "kui-crm"
import NotesBlock from "../../../../components/common/NoteBlock"
import Container from "../../../../components/ui/Container"
import useSCStore from "../../store"
import SCInfoFields from "../SCInfoFields"
import ClientPersonalDataCell from "../../../../components/common/ClientPersonalDataCell"
import ContractsTable from "../../../../components/common/ContractsTable"
import SCFieldsHeader from "../SCFieldsHeader"
import ChangesTable from "../../../../components/common/ChangesTable"
import { SCInfoFormFields } from "../SCInfoFields/types"
import { SCInfoFullSchema } from "../SCInfoFields/schemes"

function SCInfo() {
  const {
    notesStore,
    contractInfoStore,
    contractChangesStore,
    documentsStore,
  } = useSCStore()
  const form = useForm<SCInfoFormFields>({
    defaultValues: {
      files: documentsStore.documents,
      ...contractInfoStore.contractInfoValues,
    },
    ...(contractInfoStore.isPending && {
      resolver: yupResolver(SCInfoFullSchema),
    }),
  })

  return (
    <Box pt={2} pb={3}>
      <Container>
        <StyledStickyWrapper>
          <Box mb={2}>
            <StyledUserData
              type="left"
              label="Landlord"
              personalData={contractInfoStore.landlord}
            />
          </Box>

          <SCFieldsHeader form={form} />
        </StyledStickyWrapper>

        <SCInfoFields form={form} />
      </Container>

      <ContractsTable
        label="Rental contracts"
        rentalContracts={contractInfoStore.rentalContracts}
        withApartment
      />

      <Container>
        <ChangesTable
          variant="applied"
          changes={contractChangesStore.appliedChangesLines}
        />
        {notesStore && <NotesBlock notesStore={notesStore} />}
      </Container>
      <LoaderState loader={contractChangesStore.loader} />
    </Box>
  )
}

export default observer(SCInfo)

const StyledUserData = styled(ClientPersonalDataCell)`
  margin-top: 0;
  padding-right: 0;
`

const StyledStickyWrapper = styled.div`
  position: sticky;
  top: 121px;
  left: 0;
  background: white;
  z-index: 4;
`
