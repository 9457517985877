import React from "react"
import { useForm } from "react-hook-form"
import { Caption, Grid } from "kui-basic"
import { observer } from "mobx-react"
import { FormWrapper, InputByType } from "kui-crm"
import {
  AvailableResourcesStepFields,
  getAvailableResourcesFields,
} from "kui-crm_actions"
import useApartmentLiteStore from "../../../../../../../store/shared/apartment"

const AvailableResourcesStep = () => {
  const { inspectionsStore } = useApartmentLiteStore()
  const formStore = inspectionsStore.creationForm
  const form = useForm<AvailableResourcesStepFields>({
    defaultValues: {
      availableResources: formStore.fields?.availableResources,
    },
  })

  const fields = getAvailableResourcesFields()

  const handleSubmit = (data: AvailableResourcesStepFields) => {
    formStore.updateFormFields(data)
    formStore.nextStep()
  }

  return (
    <FormWrapper
      onSubmit={form.handleSubmit(handleSubmit)}
      form={form}
      withPrevStep
      handlePrevClick={formStore.prevStep}
      variant="next"
      label="Next"
    >
      <Grid container spacing={2}>
        <Grid item>
          <Caption size="s" color="fiftyP">
            Select the available resources with meters in the object
          </Caption>
        </Grid>
        {fields.map((field) => (
          <Grid item xs={6} key={field.name}>
            <InputByType form={form} {...field} />
          </Grid>
        ))}
      </Grid>
    </FormWrapper>
  )
}

export default observer(AvailableResourcesStep)
