import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { useForm, useWatch } from "react-hook-form"
import { Grid } from "kui-basic"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormWrapper, InputByType } from "kui-crm"
import { InspectionCommonStepFields, InspectionCommonStepProps } from "./types"
import getInspectionCommonStepFields from "./fields"
import useApartmentLiteStore from "../../../../../store/shared/apartment"
import getInspectionCommonStepSchema from "./schema"
import isContractClosed from "../../../../../utils/service/isCotractClosed"

const InspectionCommonStep = (props: InspectionCommonStepProps) => {
  const { type } = props
  const { inspectionsStore, overviewStore } = useApartmentLiteStore()
  const formStore = inspectionsStore.creationForm
  const { actualRentalContractId, actualServiceContractId } = overviewStore
  const schema = getInspectionCommonStepSchema(
    !!actualRentalContractId,
    !!actualServiceContractId
  )

  const form = useForm<InspectionCommonStepFields>({
    defaultValues: {
      type: formStore.fields?.type || type,
      date: formStore.fields?.date,
      transferType: formStore.fields?.transferType,
      clientRole: formStore.fields?.clientRole,
      dropboxLink: formStore.fields?.dropboxLink,
      contractFile: formStore.fields?.contractFile,
    },
    resolver: yupResolver(schema),
  })
  const [inspectionType, transferType] = useWatch({
    control: form.control,
    name: ["type", "transferType"],
  })
  const shouldUploadContract =
    (inspectionType === "inventory" && !actualServiceContractId) ||
    (transferType === "maroom_tenant" && !actualRentalContractId)

  const fields = getInspectionCommonStepFields(
    !type,
    inspectionType,
    formStore.fields,
    shouldUploadContract
  )

  const handleSubmit = (data: InspectionCommonStepFields) => {
    const dataWithApartment = {
      ...data,
      apartmentId: inspectionsStore?.apartmentId,
    }

    if (!formStore.fields?.basedOn && data.type === "appraisal") {
      formStore.updateFormFields({
        ...dataWithApartment,
        clientRole: "landlord",
      })
    } else formStore.updateFormFields(dataWithApartment)
    formStore.nextStep()
  }

  useEffect(() => {
    if (
      inspectionType === "appraisal" &&
      isContractClosed(formStore?.fields?.rentalContractStatus)
    ) {
      formStore.updateFormFields({ clientRole: "landlord" })
    }
  }, [])

  return (
    <FormWrapper
      form={form}
      onSubmit={handleSubmit}
      label="Next"
      variant="next"
    >
      <Grid container spacing={2}>
        {fields.map((field) => (
          <Grid item xs={12}>
            <InputByType form={form} {...field} />
          </Grid>
        ))}
      </Grid>
    </FormWrapper>
  )
}

export default observer(InspectionCommonStep)
