import React from "react"
import { ModalProps, Modal } from "kui-crm"
import { Box, Caption } from "kui-basic"
import useExpensesStore from "../../../../store"
import ClosePeriodForm from "../../../../forms/summary/ClosePeriodForm"
import { ClosePeriodFormFields } from "../../../../forms/summary/ClosePeriodForm/types"
import ClosePeriodWarning from "../ClosePeriodWarning"

function ClosePeriodModal(props: ModalProps) {
  const { open, handleClose } = props
  const { overviewStore, periodsStore } = useExpensesStore()
  const { selectedPeriod } = periodsStore

  const handleSubmit = async (data: ClosePeriodFormFields) => {
    if (overviewStore.id) {
      await selectedPeriod?.closePeriod(overviewStore.id, data)
    }
    handleClose()
  }

  return (
    <Modal
      data-testid="confirmation_modal"
      title="Close period"
      open={open}
      handleClose={handleClose}
    >
      <Box pr={5} pl={5}>
        {selectedPeriod?.withInitialCalculation && <ClosePeriodWarning />}
        <Caption size="s" color="fiftyP">
          Adding confirmation of payment to the landlord
        </Caption>
      </Box>
      <ClosePeriodForm handleSubmit={handleSubmit} />
    </Modal>
  )
}

export default ClosePeriodModal
